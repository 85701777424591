import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { uploadToSupabaseFileFromUrl } from "@/utils/mediaUtil.ts";
import { sanitizeForURL } from "@/utils/stringUtil.ts";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { nestedPopupCountState, popupOpenState } from "@/states/ModalState.ts";
import GenerateBaseImage from "./generateImagesModal/GenerateBaseImage.tsx";
import { Tables } from "@/types/database.ts";
import { useRecoilState, useSetRecoilState } from "recoil";

interface BaseMediaGeneratorProps {
  children: ReactElement;
  story: Tables<"blueprint_stories">;
  basePrompt: string | null;
  promptModifier: string | null;
  baseFileName: string | undefined | null;
  imageReferenceFileName: string | null;
  onUploadComplete: (fileName: string) => void;

  onClose(baseMediaSupabaseUrl: string): void;
}

export default function MediaGeneratorBaseModalButton({
  children,
  story,
  basePrompt,
  promptModifier,
  baseFileName,
  imageReferenceFileName,
  onUploadComplete,
  onClose,
}: BaseMediaGeneratorProps) {
  const [isOpen, setIsOpen] = useState(false);
  const setPopupOpen = useSetRecoilState(popupOpenState);
  const [nestedPopupCount, setNestedPopupCount] = useRecoilState(nestedPopupCountState);
  const [selectedImageReplicateUrl, setSelectedImageReplicateUrl] = useState<string | null>(null);
  const [selectedImageSupabaseUrl, setSelectedImageSupabaseUrl] = useState<string | null>(null);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const dialogRef = useRef<HTMLDivElement>(null);

  // Function to reset all states to their initial values
  const resetStates = () => {
    setSelectedImageReplicateUrl(null);
    setSelectedImageSupabaseUrl(null);
    setStepIndex(0);
    setUploadStatus("");
  };

  const openPopup = () => {
    // Reset all states before opening the modal
    resetStates();
    setIsOpen(true);
    setPopupOpen(true);
    setNestedPopupCount((prevCount) => prevCount + 1);
  };

  const closePopup = async () => {
    // Handle callbacks with current values before resetting states
    if (selectedImageSupabaseUrl) onClose(selectedImageSupabaseUrl);
    else if (onUploadComplete) onUploadComplete("");
    
    // Close the modal
    setIsOpen(false);
    setPopupOpen(false);
    setNestedPopupCount((prevCount) => Math.max(0, prevCount - 1));
    
    // Reset states after modal is closed
    // Use a short timeout to ensure UI transitions complete before state reset
    setTimeout(() => {
      resetStates();
    }, 100);
  };

  // Set/unset popup state when dialog opens/closes
  useEffect(() => {
    if (isOpen) {
      setPopupOpen(true);
      setNestedPopupCount((prevCount) => prevCount + 1);
    }

    return () => {
      if (!isOpen) {
        setNestedPopupCount((prevCount) => Math.max(0, prevCount - 1));
        // Only clear popup state if this was the last modal
        if (nestedPopupCount <= 1) {
          setPopupOpen(false);
        }
      }
    };
  }, [isOpen]); // Only depend on isOpen, not on the state setters or the count itself

  const uploadGeneratedImage = async () => {
    if (!selectedImageReplicateUrl) return;
    setUploadStatus("Uploading Image...");
    const fileNameInStorage = `${sanitizeForURL(baseFileName || "generated")}-${Date.now()}.jpg`;
    const url = await uploadToSupabaseFileFromUrl(
      story.id,
      selectedImageReplicateUrl,
      fileNameInStorage,
    );
    setSelectedImageSupabaseUrl(url);
    console.log("uploaded address", url);
    onUploadComplete(fileNameInStorage);
    setStepIndex(stepIndex + 1);
    setUploadStatus("");
  };

  return (
    <>
      <SpokableButton onClick={openPopup}>
        <SparklesIcon className="mr-2" />
        Generate
      </SpokableButton>

      <Dialog
        open={isOpen}
        onClose={closePopup}
        className="relative z-[299]"
        initialFocus={dialogRef}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" style={{ zIndex: 290 }} />
        <div
          className="fixed inset-0 flex items-center justify-center p-4"
          style={{ zIndex: 299 }}
          ref={dialogRef}
          tabIndex={-1}
        >
          <DialogPanel
            className="w-5/6 max-h-[90vh] transform rounded-md bg-white shadow-xl transition-all flex flex-col"
            style={{ pointerEvents: "auto" }}
          >
            <DialogTitle className="font-serif p-6 text-4xl font-bold shrink-0">
              Generate Visuals
            </DialogTitle>
            <div className="flex-1 min-h-0 overflow-y-auto pl-8">
              {stepIndex === 0 && (
                <GenerateBaseImage
                  storyId={story.id}
                  initialPrompt={basePrompt}
                  initialPromptModifier={promptModifier}
                  onImageSelected={(imageUrl) => {
                    setSelectedImageReplicateUrl(imageUrl);
                  }}
                  imageReferenceFileName={imageReferenceFileName}
                  imageModelId={story.blueprint_image_model_id}
                />
              )}
              {stepIndex === 1 &&
                React.cloneElement(children, {
                  baseImageUrlWithStory: selectedImageSupabaseUrl,
                  storyId: story.id,
                })}
            </div>
            <div className="p-6 bg-gray-50 border-t mt-auto shrink-0">
              <div className="flex justify-between items-center">
                {stepIndex === 0 && (
                  <SpokableButton
                    onClick={() => uploadGeneratedImage()}
                    disabled={!selectedImageReplicateUrl}
                  >
                    Use Selected Image
                  </SpokableButton>
                )}
                {stepIndex === 1 && (
                  <SpokableButton color="light" onClick={() => setStepIndex(stepIndex - 1)}>
                    Previous
                  </SpokableButton>
                )}

                {uploadStatus && <p>{uploadStatus}</p>}
                <SpokableButton onClick={closePopup} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
