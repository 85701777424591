import { useState } from "react";
import { useRecoilState } from "recoil";
import { modalMomentsGenerateState } from "@/states/ModalState.ts";
import { useApiCall } from "@/components/admin/generateStory/useGenerateHooks.ts";
import {
  LEARNING,
  MomentType,
  MONOLOGUE,
  TESTING,
} from "@/components/admin/storyGraph/common/types.ts";
import { MomentBaseOutput } from "@/types/moment_base_prompts_generated_types.ts";

export const useGenerateSteps = (momentType: MomentType) => {
  const [isOpen, setIsOpen] = useRecoilState(modalMomentsGenerateState);
  const [stepIndex, setStepIndex] = useState<number>(0);

  const apiEndpoint = (() => {
    switch (momentType) {
      case LEARNING:
        return "generate_learning_moment";
      case TESTING:
        return "generate_testing_moment";
      case MONOLOGUE:
        return "generate_monologue_moment";
      default:
        return "generate_learning_moment";
    }
  })();

  const {
    data: generatedMoment,
    status: momentStatus,
    callApi: generateMoment,
    setData: setGeneratedMoment,
  } = useApiCall<MomentBaseOutput>(apiEndpoint);

  const handleClose = () => {
    setIsOpen(false);
    setStepIndex(0);
    setGeneratedMoment(null);
  };

  return {
    isOpen,
    setIsOpen,
    stepIndex,
    setStepIndex,
    generatedMoment,
    momentStatus,
    generateMoment,
    setGeneratedMoment,
    handleClose,
  };
};
