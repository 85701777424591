import { useState } from "react";
import { supabase } from "@/vendor/supabaseClient.ts";
import { GenerateCharacterEmotionVideos } from "./generateImagesModal/GenerateCharacterEmotionVideos.tsx";
import { Tables } from "@/types/database.ts";
import MediaGeneratorBaseModalButton from "@/components/admin/MediaGeneratorBaseModalButton.tsx";
import { useSetRecoilState } from "recoil";
import { popupOpenState } from "@/states/ModalState.ts";
import { getImageNameWithoutStoryId } from "@/utils/mediaUtil.ts";

interface ImageGeneratorUploaderButtonProps {
  story: Tables<"blueprint_stories">;
  characterId: string;
  baseFileName: string | undefined | null;
  onUploadComplete: (fileName: string) => void;
  basePrompt: string | null;
  promptModifier: string | null;
  imageReferenceFileName: string | null;
}

export default function MediaGeneratorCharacterModalButton({
  characterId,
  story,
  onUploadComplete,
  basePrompt,
  promptModifier,
  baseFileName,
  imageReferenceFileName,
}: ImageGeneratorUploaderButtonProps) {
  const [selectedImageSupabaseUrl] = useState<string | null>(null);
  const setIsOpen = useSetRecoilState(popupOpenState);

  const onClose = async (mediaBaseSupabaseUrl: string) => {
    const fileName = getImageNameWithoutStoryId(mediaBaseSupabaseUrl);
    console.log("mediaBaseSupabaseUrl", fileName);
    if (!fileName) return;
    const { error } = await supabase.from("blueprint_character_medias").insert({
      media_url: fileName,
      blueprint_character_id: characterId,
      blueprint_story_id: story.id,
    });
    if (error) console.log("error inserting image", mediaBaseSupabaseUrl);
    onUploadComplete(mediaBaseSupabaseUrl);
    setIsOpen(false);
  };

  return (
    <MediaGeneratorBaseModalButton
      story={story}
      basePrompt={basePrompt}
      promptModifier={promptModifier}
      baseFileName={baseFileName}
      imageReferenceFileName={imageReferenceFileName}
      onUploadComplete={onUploadComplete}
      onClose={onClose}
    >
      <GenerateCharacterEmotionVideos
        baseImageUrlWithStory={selectedImageSupabaseUrl}
        storyId={story.id}
        onVideosCompleted={onClose}
      />
    </MediaGeneratorBaseModalButton>
  );
}
