import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { Tables } from "@/types/database.ts";

import { Badge } from "../../catalyst/badge.tsx";
import SaveAll from "./SaveAll.tsx";
import { SpokableButton } from "../SpokableButton.tsx";
import {
  LEARNING,
  MomentType,
  MONOLOGUE,
  TESTING,
} from "@/components/admin/storyGraph/common/types.ts";
import GenerateLearningMoment from "@/components/admin/generateMomentsModal/learning/GenerateLearningMoment.tsx";
import SelectSyllabusConcept from "@/components/admin/generateMomentsModal/learning/SelectSyllabusConcept.tsx";
import { ConceptItem, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import { TEACHING_STYLE_ANALOGY, TEST_STYLE_CONVINCE } from "@/constants/constant.ts";
import SelectTestingSyllabusConcept from "@/components/admin/generateMomentsModal/testing/SelectTestingSyllabusConcept.tsx";
import GenerateTestingMoment from "@/components/admin/generateMomentsModal/testing/GenerateTestingMoment.tsx";
import GenerateMonologueMoment from "@/components/admin/generateMomentsModal/monologue/GenerateMonologueMoment.tsx";

export interface GenerateMomentModalProps {
  story: Tables<"blueprint_stories"> | null;
  beats: Tables<"blueprint_beats">[];
  beatsheets: Tables<"blueprint_beatsheets">[];
  branches: Tables<"blueprint_branches">[];
  characters: Tables<"blueprint_characters">[];
  sceneBranch: Tables<"blueprint_branches"> | null | undefined;
  sceneBeatsheet: Tables<"blueprint_beatsheets"> | null | undefined;
  branchTransitions: Tables<"blueprint_branch_transitions">[] | null;
  previousScenes: Tables<"blueprint_scenes">[] | null;
  previousMomentsInScene: Tables<"blueprint_moments">[] | null;
  allMoments: Tables<"blueprint_moments">[] | null;
  allMomentTransitions: Tables<"blueprint_moment_transitions">[];
  onCloseCallback: () => void;
  scene: Tables<"blueprint_scenes"> | null | undefined;
  momentType: MomentType;
  existingMomentId?: string;
}

const GenerateMomentModal: React.FC<GenerateMomentModalProps> = ({
  story,
  beatsheets,
  characters,
  sceneBranch,
  previousScenes,
  previousMomentsInScene,
  allMomentTransitions,
  scene,
  momentType,
  allMoments,
  onCloseCallback,
  existingMomentId,
}) => {
  const {
    isOpen,
    stepIndex,
    setStepIndex,
    handleClose,
    generatedMoment,
    momentStatus,
    generateMoment,
    setGeneratedMoment,
  } = useGenerateSteps(momentType);
  const [syllabus, setSyllabus] = useState<SyllabusOutput | null>(null);
  const [selectedSyllabusConcept, setSelectedSyllabusConcept] = useState<
    ConceptItem | null | undefined
  >(null);

  const [selectedTeachingStyle, setSelectedTeachingStyle] = useState<string | null>(
    TEACHING_STYLE_ANALOGY,
  );
  const [selectedLearningStyle, setSelectedLearningStyle] = useState<string | null>(
    TEST_STYLE_CONVINCE,
  );
  const [testingMaxMistakes, setTestingMaxMistakes] = useState<number>(3);

  const closePopup = () => {
    handleClose();
    onCloseCallback();
  };

  const getSteps = () => {
    // Create the final SaveAll step that will be common for all moment types
    const finalSaveStep = (
      <SaveAll
        key="save-step"
        moment={generatedMoment}
        scene={scene}
        story={story}
        momentType={momentType}
        selectedSyllabusConcept={selectedSyllabusConcept}
        teachingStyle={selectedTeachingStyle}
        handleClose={closePopup}
        allMoments={allMoments}
        allMomentTransitions={allMomentTransitions}
        previousScenes={previousScenes}
        selectedLearningStyle={selectedLearningStyle}
        testingMaxMistakes={momentType === TESTING ? testingMaxMistakes : undefined}
        existingMomentId={existingMomentId}
      />
    );

    const typeSpecificSteps = (() => {
      switch (momentType) {
        case LEARNING:
          return [
            <SelectSyllabusConcept
              key="learning-step-0"
              story={story}
              syllabus={syllabus}
              setSyllabus={setSyllabus}
              selectedSyllabusConcept={selectedSyllabusConcept}
              setSelectedSyllabusConcept={setSelectedSyllabusConcept}
              selectedTeachingStyle={selectedTeachingStyle}
              setSelectedTeachingStyle={setSelectedTeachingStyle}
              allMoments={allMoments || []}
            />,
            <GenerateLearningMoment
              story={story}
              key="learning-step-1"
              status={momentStatus}
              generateMoment={generateMoment}
              setGeneratedMoment={setGeneratedMoment}
              scene={scene}
              generatedMoment={generatedMoment}
              characters={characters}
              beatsheets={beatsheets}
              sceneBranch={sceneBranch}
              previousScenes={previousScenes}
              previousMomentsInScene={previousMomentsInScene}
              selectedSyllabusConcept={selectedSyllabusConcept}
              selectedTeachingStyle={selectedTeachingStyle}
              syllabus={syllabus}
            />,
          ];
        case TESTING:
          return [
            <SelectTestingSyllabusConcept
              key="testing-step-0"
              story={story}
              syllabus={syllabus}
              setSyllabus={setSyllabus}
              selectedSyllabusConcept={selectedSyllabusConcept}
              setSelectedSyllabusConcept={setSelectedSyllabusConcept}
              selectedTestingStyle={selectedTeachingStyle}
              setSelectedTestingStyle={setSelectedLearningStyle}
              allMoments={allMoments}
              testingMaxMistakes={testingMaxMistakes}
              setTestingMaxMistakes={setTestingMaxMistakes}
            />,
            <GenerateTestingMoment
              story={story}
              key="testing-step-1"
              status={momentStatus}
              generateMoment={generateMoment}
              setGeneratedMoment={setGeneratedMoment}
              scene={scene}
              generatedMoment={generatedMoment}
              characters={characters}
              beatsheets={beatsheets}
              sceneBranch={sceneBranch}
              previousScenes={previousScenes}
              previousMomentsInScene={previousMomentsInScene}
              selectedSyllabusConcept={selectedSyllabusConcept}
              selectedTestingStyle={selectedLearningStyle}
              syllabus={syllabus}
              testingMaxMistakes={testingMaxMistakes}
            />,
          ];
        case MONOLOGUE:
          return [
            <GenerateMonologueMoment
              story={story}
              key="monologue-step-1"
              status={momentStatus}
              generateMoment={generateMoment}
              setGeneratedMoment={setGeneratedMoment}
              scene={scene}
              generatedMoment={generatedMoment}
              characters={characters}
              beatsheets={beatsheets}
              sceneBranch={sceneBranch}
              previousScenes={previousScenes}
              previousMomentsInScene={previousMomentsInScene}
            />,
          ];
        default:
          return [];
      }
    })();

    // Return all steps with SaveAll always as the last step
    return [...typeSpecificSteps, finalSaveStep];
  };
  const steps = getSteps();
  const maxStepIndex = steps.length - 1;

  const isNextButtonDisabled = () => {
    if (stepIndex !== 0) return false;

    switch (momentType) {
      case LEARNING:
      case TESTING:
        return !selectedSyllabusConcept;
      case MONOLOGUE:
        return false;
      default:
        return true;
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 font-nunito"
        onClose={() => {
          handleClose();
          onCloseCallback();
        }}
      >
        <div className="fixed inset-0 bg-black/60" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-6">
            <Dialog.Panel className="w-2/3 max-h-[95vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all">
              {scene && (
                <div className="pt-6 ml-6">
                  <Badge>{scene.name}</Badge>
                </div>
              )}
              <div className="p-6">
                {/* Render the step corresponding to the current step index */}
                {steps[stepIndex]}
                <div className="mt-4 flex justify-between">
                  <div>
                    {stepIndex > 0 && (
                      <SpokableButton
                        color="light"
                        onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                        className="mr-2"
                      >
                        Previous
                      </SpokableButton>
                    )}
                    {stepIndex < maxStepIndex && (
                      <SpokableButton
                        onClick={() => setStepIndex(stepIndex + 1)}
                        disabled={isNextButtonDisabled()}
                      >
                        Next
                      </SpokableButton>
                    )}
                  </div>
                  <SpokableButton color="light" onClick={handleClose}>
                    Close
                  </SpokableButton>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default GenerateMomentModal;
