import { Tables } from "@/types/database";
import FormField from "../FormField";
import StorageFileSelector from "../StorageFileSelector";
import { SOUND_BUCKET_NAME } from "@/constants/constant";
import SoundGeneratorButton from "../SoundGeneratorButton";
import StorageFileAudioPlayer from "../StorageFileAudioPlayer";
import NumericStepper from "../NumericStepper";

interface SoundMomentDetailsProps {
  moment: Tables<"blueprint_moments">;
  storyId: string;
  onMomentChange: (moment: Tables<"blueprint_moments">) => void;
  setIsDirty: (isDirty: boolean) => void;
}

export function SoundMomentDetails({
  moment,
  storyId,
  onMomentChange,
  setIsDirty
}: SoundMomentDetailsProps) {
  return (
    <>
      <FormField label="Intro">
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <StorageFileSelector
            bucketName={SOUND_BUCKET_NAME}
            storyId={moment.blueprint_story_id}
            onStorageFileSelected={(storageFileUrl) => {
              onMomentChange({ ...moment, sound_intro: storageFileUrl });
              setIsDirty(true);
            }}
            value={moment.sound_intro}
          />
          <SoundGeneratorButton
            storyId={storyId}
            onUploadComplete={(storageFileUrl) => {
              onMomentChange({ ...moment, sound_intro: storageFileUrl });
              setIsDirty(true);
            }}
            prompt={moment.prompt_sound_intro_generation}
          />
        </div>
        {moment.sound_intro && (
          <StorageFileAudioPlayer
            bucketName={SOUND_BUCKET_NAME}
            storyId={moment.blueprint_story_id}
            fileName={moment.sound_intro}
          />
        )}
      </FormField>

      <FormField label="Background">
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <StorageFileSelector
            bucketName={SOUND_BUCKET_NAME}
            storyId={moment.blueprint_story_id}
            onStorageFileSelected={(storageFileUrl) => {
              onMomentChange({ ...moment, sound_outro: storageFileUrl });
              setIsDirty(true);
            }}
            value={moment.sound_outro}
          />
          <SoundGeneratorButton
            storyId={storyId}
            onUploadComplete={(storageFileUrl) => {
              onMomentChange({ ...moment, sound_outro: storageFileUrl });
              setIsDirty(true);
            }}
            prompt={moment.prompt_sound_outro_generation}
          />
        </div>
        {moment.sound_outro && (
          <StorageFileAudioPlayer
            bucketName={SOUND_BUCKET_NAME}
            storyId={moment.blueprint_story_id}
            fileName={moment.sound_outro}
          />
        )}
      </FormField>

      <div className="w-full lg:w-1/3">
        <div className="flex justify-between">
          <FormField label="Sound Volume">
            <NumericStepper
              value={moment.sound_intro_volume}
              onChange={(e) => {
                onMomentChange({ ...moment, sound_intro_volume: e });
                setIsDirty(true);
              }}
              label=""
            />
          </FormField>
        </div>
      </div>
    </>
  );
} 