import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet.tsx";
import { StoryGraphNode } from "@/components/admin/storyGraph/common/types.ts";
import { useRecoilValue } from "recoil";
import { popupOpenState } from "@/states/ModalState.ts";

interface PropertyPanelProps {
  selectedNode: StoryGraphNode | null;
  onClose: () => void;
  children?: React.ReactNode;
}

/**
 * A right-side panel for editing properties of a selected node.
 * Includes special handling for when popups are open to prevent interaction issues.
 */
const PropertyPanel = ({ selectedNode, onClose, children }: PropertyPanelProps) => {
  const isInitialized = !!selectedNode && selectedNode.data.isInitialized;
  const isPopupOpen = useRecoilValue(popupOpenState);
  
  // Custom handler to prevent closing when a popup is open
  const handleOpenChange = (open: boolean) => {
    if (!open && !isPopupOpen) {
      onClose();
    }
  };

  return (
    <Sheet open={isInitialized} onOpenChange={handleOpenChange}>
      <SheetContent 
        side="right" 
        className="w-1/3 sm:max-w-none flex flex-col h-full"
        style={{
          // Only disable pointer events when a popup is open
          pointerEvents: isPopupOpen ? 'none' : 'auto',
        }}
      >
        <SheetHeader className="hidden">
          <SheetTitle>Edit Scene</SheetTitle>
        </SheetHeader>
        {selectedNode && (
          <div className="flex flex-col h-full p-6">
            <div className="flex-1 overflow-y-auto">
              {children}
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default PropertyPanel;
