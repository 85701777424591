import { useState, useEffect } from 'react';
import { Tables, TablesInsert } from "@/types/database.ts";
import { supabase } from "@/vendor/supabaseClient.ts";
import { getEnvironment } from "@/utils/envUtil.ts";
import { ConceptItem, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import { getSyllabusFromStoryId } from "@/components/admin/syllabusManagement/useSyllabusDatabase.ts";
import { moveCharacterImageToMomentImage } from "@/utils/mediaUtil.ts";
import {
  useCharacterImageFetcher,
  useFetchAllMomentData,
  useFetchMoments,
  useFetchStory,
  useMomentImageFetcher,
} from '@/hooks/database/useMoment.ts';
import { useRecoilState } from "recoil";
import { modalMomentsGenerateState } from "@/states/ModalState.ts";

interface UseMomentDetailProps {
  onMomentUpdate?: (updatedMoment: Tables<"blueprint_moments">) => void;
}

export function useMomentDetail(momentId: string | undefined, { onMomentUpdate }: UseMomentDetailProps = {}) {
  const [isDirty, setIsDirty] = useState(false);
  const [moment, setMoment] = useState<Tables<"blueprint_moments"> | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [characterImages, setCharacterImages] = useState<Tables<"blueprint_character_medias">[] | null>(null);
  const [momentImages, setMomentImages] = useState<Tables<"blueprint_moment_medias">[] | null>(null);
  const [syllabus, setSyllabus] = useState<SyllabusOutput | null>(null);
  const [selectedSyllabusConcept, setSelectedSyllabusConcept] = useState<ConceptItem | null>(null);
  const [isSelectingConcept, setIsSelectingConcept] = useState(false);
  const [, setIsModalMomentsGenerateOpen] = useRecoilState(modalMomentsGenerateState);

  const { fetchAllMomentData, ...allMomentData } = useFetchAllMomentData();
  const { moments, fetchMoments } = useFetchMoments();
  const { fetchCharacterImages } = useCharacterImageFetcher();
  const { fetchMomentImages } = useMomentImageFetcher();
  const { fetchStory, story } = useFetchStory();

  useEffect(() => {
    if (momentId) {
      fetchAllMomentData(momentId);
    }
  }, [momentId]);

  useEffect(() => {
    setMoment(allMomentData.momentData.moment);
    if (allMomentData.momentData.moment?.blueprint_story_id) {
      fetchMoments(allMomentData.momentData.moment.blueprint_story_id);
    }
  }, [allMomentData.momentData.moment]);

  useEffect(() => {
    fetchStory(moment?.blueprint_story_id);
    retrieveMomentImages();
    fetchLearningData();
  }, [moment]);

  useEffect(() => {
    if (!allMomentData.momentData.moment?.blueprint_character_id) return;
    retrieveCharacterImage(allMomentData.momentData.moment?.blueprint_character_id);
  }, [allMomentData.momentData.moment?.blueprint_character_id]);

  async function retrieveCharacterImage(characterId: string) {
    const images = await fetchCharacterImages(characterId);
    setCharacterImages(images);
  }

  async function retrieveMomentImages() {
    if (!moment) return;
    const images = await fetchMomentImages(moment.id);
    setMomentImages(images);
  }

  async function fetchLearningData() {
    if (!moment) return;
    const syllabus = await getSyllabusFromStoryId(moment?.blueprint_story_id);
    setSyllabus(syllabus);
    const currentConcept = syllabus?.items.find(
      (item) => item.sub_concept_id === moment.blueprint_learning_sub_concept_id,
    ) ?? null;
    setSelectedSyllabusConcept(currentConcept);
  }

  async function updateMoment() {
    if (!moment || !momentId) return;

    const { id, ...momentWithoutId } = moment;
    const { error } = await supabase
      .from("blueprint_moments")
      .update(momentWithoutId)
      .eq("id", momentId)
      .select();

    if (error) {
      setErrorMessage("Error updating moment: " + error.message);
    } else {
      if (onMomentUpdate) onMomentUpdate(moment);
      setErrorMessage("Saved!");
      setIsDirty(false);
      reload();
    }
  }

  async function createCharacter() {
    if (!moment) return;
    const { data: voice, error: voiceError } = await supabase
      .from("blueprint_voices")
      .select("*")
      .eq("environment", getEnvironment())
      .limit(1)
      .single();

    if (!voice) return;

    const characterData: TablesInsert<"blueprint_characters"> = {
      name: "New character",
      voice_id: voice.id,
      blueprint_story_id: moment?.blueprint_story_id,
    };

    const { data, error } = await supabase
      .from("blueprint_characters")
      .insert(characterData)
      .select()
      .single();

    if (error) {
      setErrorMessage("Error updating character: " + error.message);
      return null;
    } else if (voiceError) {
      setErrorMessage("Error updating character: " + voiceError);
      return null;
    }
    
    return data as Tables<"blueprint_characters">;
  }

  async function createMomentImageFromExisting(fileName: string) {
    if (!moment) return;

    moveCharacterImageToMomentImage(fileName, moment.blueprint_story_id);

    const imageData: TablesInsert<"blueprint_moment_medias"> = {
      media_url: fileName,
      blueprint_moment_id: moment.id,
      blueprint_story_id: moment?.blueprint_story_id,
    };

    const { error } = await supabase.from("blueprint_moment_medias").insert(imageData);

    if (error) {
      setErrorMessage("Error creating Moment images: " + error.message);
    } else {
      retrieveMomentImages();
    }
  }

  async function handleContextCheckboxChange(contextId: string, isChecked: boolean) {
    if (!moment) return;
    if (isChecked) {
      const contextData: TablesInsert<"blueprint_moment_context_links"> = {
        moment_id: moment?.id,
        context_block_id: contextId,
      };

      const { error } = await supabase
        .from("blueprint_moment_context_links")
        .upsert(contextData)
        .select()
        .single();

      if (error) {
        setErrorMessage("Error creating context link: " + error.message);
      }
    } else {
      const { error } = await supabase
        .from("blueprint_moment_context_links")
        .delete()
        .eq("context_block_id", contextId)
        .eq("moment_id", moment.id);
      if (error) {
        setErrorMessage("Error deleting context link: " + error.message);
      }
    }
    reload();
  }

  function reload() {
    if (momentId) fetchAllMomentData(momentId);
  }

  // Function to handle regenerating a moment
  function handleRegenerate() {
    if (!moment) return;
    
    // Open the GenerateMomentsModal by setting the recoil state to true
    setIsModalMomentsGenerateOpen(true);
  }

  return {
    moment,
    setMoment,
    story,
    moments,
    isDirty,
    setIsDirty,
    errorMessage,
    setErrorMessage,
    characterImages,
    momentImages,
    syllabus,
    setSyllabus,
    selectedSyllabusConcept,
    isSelectingConcept,
    setIsSelectingConcept,
    allMomentData,
    updateMoment,
    createCharacter,
    createMomentImageFromExisting,
    handleContextCheckboxChange,
    retrieveCharacterImage,
    retrieveMomentImages,
    handleRegenerate,
    reload
  };
} 