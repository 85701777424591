import { useCallback, useRef } from "react";
import { DTOGameInput } from "@/types/fastApiGameEngineTypes";
import { Tables } from "@/types/database.ts";
import { LEARNING, TESTING } from "@/components/admin/storyGraph/common/types.ts";
import { getSyllabusFromStoryId } from "@/components/admin/syllabusManagement/useSyllabusDatabase.ts";

type MomentLearningFields = Pick<
  DTOGameInput,
  "syllabus_concept" | "learning_teaching_style" | "prior_knowledge" | "complete_syllabus"
>;

async function fetchMomentLearningDetails(
  momentData: Tables<"game_engine_view">,
): Promise<MomentLearningFields> {
  const syllabus = await getSyllabusFromStoryId(momentData.blueprint_story_id || "");
  const currentConcept = syllabus?.items.find(
    (item) => item.sub_concept_id === momentData.blueprint_learning_sub_concept_id,
  );

  // Find all previous concept items
  const previousConcepts = syllabus?.items.filter((item) =>
    momentData.previous_learning_sub_concepts?.includes(item.sub_concept_id),
  );
  return {
    complete_syllabus: JSON.stringify(syllabus),
    prior_knowledge: JSON.stringify(previousConcepts),
    syllabus_concept: JSON.stringify(currentConcept),
  };
}

export function useGameInputPreparation(): {
  prepareGameInput: (momentData: Tables<"game_engine_view">) => Promise<DTOGameInput>;
} {
  const cache = useRef<Record<string, MomentLearningFields>>({});

  const prepareGameInput = useCallback(
    async (momentData: Tables<"game_engine_view">): Promise<DTOGameInput> => {
      const gameInput: DTOGameInput = { ...momentData };

      if (!momentData.blueprint_moment_id) return gameInput;

      if (cache.current[momentData.blueprint_moment_id]) {
        return {
          ...momentData,
          ...cache.current[momentData.blueprint_moment_id],
        };
      }

      // Fetch additional details based on moment type
      try {
        let additionalDetails: MomentLearningFields | undefined;

        if (momentData.moment_type === LEARNING || momentData.moment_type === TESTING) {
          additionalDetails = await fetchMomentLearningDetails(momentData);
        }

        let testingDetails = {};
        if (momentData.moment_type === TESTING) {
          testingDetails = {
            current_number_of_mistakes: 0,
          };
        }

        if (additionalDetails) {
          cache.current[momentData.blueprint_moment_id] = additionalDetails;
          return {
            ...momentData,
            ...additionalDetails,
            ...testingDetails,
          };
        }
        console.log("gameInput prepared", gameInput);
        return gameInput;
      } catch (error) {
        console.error("Error preparing game input:", error);
        return gameInput;
      }
    },
    [],
  );

  return { prepareGameInput };
}
