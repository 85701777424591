import React from "react";
import { useRecoilState } from "recoil";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { gameEngineModalState } from "@/states/ModalState.ts";
import GameScreen from "@/pages/GameScreen.tsx";
import { Description } from "@radix-ui/react-dialog";
import { useGameEngineModal } from "@/hooks/useGameEngineModal.ts";

export const GameEngineModal: React.FC = () => {
  const [modalState, setModalState] = useRecoilState(gameEngineModalState);
  const { resetGameState, stopAllAudioPlayback } = useGameEngineModal();

  const handleClose = () => {
    // Immediately stop all audio playback first (for immediate effect)
    stopAllAudioPlayback();
    
    // Then do a full state reset
    resetGameState();
    
    // Finally close the modal
    setModalState({ isOpen: false, storyId: null });
  };

  if (!modalState.storyId) return null;

  return (
    <Dialog open={modalState.isOpen} onOpenChange={handleClose}>
      <DialogHeader>
        <DialogTitle />
        <Description>Game engine</Description>
      </DialogHeader>
      <DialogContent className="max-w-[90vw] max-h-[90vh] h-full w-full">
        <div className="overflow-auto h-full">
          <GameScreen />
        </div>
      </DialogContent>
    </Dialog>
  );
};
