import { useCallback, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { audioContextState } from "../states/audioDataState";

interface WindowWithWebkitAudioContext extends Window {
  webkitAudioContext?: typeof AudioContext;
}

export function useSetupAudioContext() {
  const [audioContext, setAudioContext] = useRecoilState<AudioContext | null>(audioContextState);
  const [audioInitiated, setAudioInitiated] = useState<boolean>(false);

  // Reset audioInitiated when audioContext is null
  useEffect(() => {
    if (!audioContext) {
      setAudioInitiated(false);
    }
  }, [audioContext]);

  return useCallback(
    (callback?: () => void) => {
      // If we have a valid audioContext that's not closed, use it
      if (audioContext && audioContext.state !== "closed") {
        console.log(`ℹ️ Using existing AudioContext (state: ${audioContext.state})`);
        
        // Resume if it was suspended
        if (audioContext.state === "suspended") {
          console.log("▶️ Resuming suspended AudioContext...");
          audioContext.resume()
            .then(() => console.log("✅ AudioContext resumed successfully"))
            .catch(err => console.error("❌ Error resuming AudioContext:", err));
        }
        
        // Execute callback even when reusing existing context
        if (callback) {
          console.log("📞 Executing audio setup callback with existing context");
          callback();
        }
        return;
      }
      
      // Create new AudioContext when needed
      if (!audioInitiated || !audioContext || audioContext.state === "closed") {
        console.log("🎵 Creating new AudioContext...");
        try {
          const newAudioContext = new (window.AudioContext ||
            (window as WindowWithWebkitAudioContext).webkitAudioContext)();
          setAudioContext(newAudioContext);
          setAudioInitiated(true);

          console.log("🔓 Attempting to unlock audio context...");
          fetch("/silent.mp3")
            .then((response) => response.arrayBuffer())
            .then((arrayBuffer) => newAudioContext.decodeAudioData(arrayBuffer))
            .then((decodedAudio) => {
              const source = newAudioContext.createBufferSource();
              source.buffer = decodedAudio;
              source.connect(newAudioContext.destination);
              source.start(0);
              console.log("🔊 Audio context unlocked successfully");
              
              // Execute callback after successful setup
              if (callback) {
                console.log("📞 Executing audio setup callback with new context");
                callback();
              }
            })
            .catch((e) => {
              console.error("❌ Error unlocking audio:", e);
              // Still try to execute callback even if unlocking failed
              if (callback) {
                callback();
              }
            });
        } catch (error) {
          console.error("❌ Error creating AudioContext:", error);
          // Still try to execute callback even if context creation failed
          if (callback) {
            callback();
          }
        }
      } else {
        console.log("ℹ️ Audio context already initiated");
        // Execute callback
        if (callback) {
          console.log("📞 Executing audio setup callback");
          callback();
        }
      }
    },
    [audioContext, setAudioContext, audioInitiated],
  );
}
