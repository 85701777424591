import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tables } from "@/types/database.ts";

import { capitalize } from "@/utils/stringUtil.ts";
import useBackgroundMusicManager from "../../hooks/useBackgroundMusicManager.ts";

import { SpokableButton } from "../../components/admin/SpokableButton.tsx";
import { useGameEngineModal } from "@/hooks/useGameEngineModal.ts";
import { LEARNING, TESTING } from "@/components/admin/storyGraph/common/types.ts";
import { DetailHeader } from "@/components/admin/moment/DetailHeader.tsx";
import { DetailCard } from "@/components/admin/moment/DetailCard.tsx";
import { BasicMomentDetails } from "@/components/admin/moment/BasicMomentDetails";
import { CharacterMomentDetails } from "@/components/admin/moment/CharacterMomentDetails";
import { SoundMomentDetails } from "@/components/admin/moment/SoundMomentDetails";
import { VisualsMomentDetails } from "@/components/admin/moment/VisualsMomentDetails";
import { LearningMomentDetails } from "@/components/admin/moment/LearningMomentDetails";
import { TestingMomentDetails } from "@/components/admin/moment/TestingMomentDetails";
import { ContextMomentDetails } from "@/components/admin/moment/ContextMomentDetails";
import { useMomentDetail } from "@/hooks/moment/useMomentDetail.ts";

interface MomentDetailProps {
  momentId?: string | null;
  onMomentUpdate?: (updatedMoment: Tables<"blueprint_moments">) => void;
  onRegenerate: () => void;
}

function MomentDetail({
  momentId: propsMomentId,
  onMomentUpdate,
  onRegenerate,
}: MomentDetailProps) {
  const { momentId: paramsMomentId } = useParams<{ momentId: string }>();
  const navigate = useNavigate();
  const { stopBackgroundMusic } = useBackgroundMusicManager();
  const { handleMomentTest } = useGameEngineModal();
  const momentId = propsMomentId || paramsMomentId;

  const {
    moment,
    setMoment,
    story,
    moments,
    isDirty,
    setIsDirty,
    errorMessage,
    setErrorMessage,
    characterImages,
    momentImages,
    syllabus,
    setSyllabus,
    selectedSyllabusConcept,
    isSelectingConcept,
    setIsSelectingConcept,
    allMomentData,
    updateMoment,
    createCharacter,
    createMomentImageFromExisting,
    handleContextCheckboxChange,
    retrieveCharacterImage,
    retrieveMomentImages,
  } = useMomentDetail(momentId, { onMomentUpdate });

  useEffect(() => {
    if (momentId) {
      stopBackgroundMusic();
    }
  }, [momentId]);

  const isFullPageAndNotASubComponent = (): boolean => {
    return !propsMomentId;
  };

  const handleCancel = () => {
    if (!moment) return;
    handleNavigation(`/admin/stories/${moment.blueprint_story_id}`);
  };

  const handleNavigation = (url: string) => {
    if (isDirty) {
      const userConfirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to navigate away?",
      );
      if (!userConfirmed) {
        return;
      }
    }
    navigate(url);
  };

  if (!moment || !story) {
    return <div>Loading...</div>;
  }

  return (
    <div className="pb-8">
      <div className="max-w-2xl mx-auto">
        <DetailHeader
          title={capitalize(moment.moment_name)}
          subtitle={capitalize(moment.moment_type)}
          label="Moment"
          imageUrls={[]}
          errorMessage={errorMessage}
          onErrorDismiss={() => setErrorMessage(null)}
          onSave={updateMoment}
          onCancel={isFullPageAndNotASubComponent() ? handleCancel : undefined}
          onRegenerate={onRegenerate}
          extraButtons={
            <SpokableButton
              onClick={() => {
                if (!story || !momentId) return;
                handleMomentTest(story.id, momentId);
              }}
            >
              Test Moment
            </SpokableButton>
          }
        />

        <DetailCard title="Basic" id="basic">
          <BasicMomentDetails moment={moment} onMomentChange={setMoment} setIsDirty={setIsDirty} />
        </DetailCard>
        <DetailCard title="Character" id="character">
          <CharacterMomentDetails
            moment={moment}
            onMomentChange={setMoment}
            setIsDirty={setIsDirty}
            characterImages={characterImages}
            onCharacterSelect={retrieveCharacterImage}
            onCreateCharacter={createCharacter}
            onEditCharacter={(characterId) => handleNavigation(`/admin/characters/${characterId}`)}
          />
        </DetailCard>
        <DetailCard title="Sound" id="sound">
          <SoundMomentDetails
            moment={moment}
            storyId={story.id}
            onMomentChange={setMoment}
            setIsDirty={setIsDirty}
          />
        </DetailCard>
        <DetailCard title="Visuals" id="image">
          <VisualsMomentDetails
            moment={moment}
            story={story}
            momentImages={momentImages}
            onStorageFileSelected={createMomentImageFromExisting}
            onImagesRefresh={retrieveMomentImages}
          />
        </DetailCard>
        {moment.moment_type === LEARNING && (
          <DetailCard title="Learning" id="learningDetail">
            <LearningMomentDetails
              moment={moment}
              story={story}
              syllabus={syllabus}
              setSyllabus={setSyllabus}
              selectedSyllabusConcept={selectedSyllabusConcept ?? null}
              isSelectingConcept={isSelectingConcept}
              setIsSelectingConcept={setIsSelectingConcept}
              onConceptChange={(value) => {
                const concept =
                  typeof value === "function" ? value(selectedSyllabusConcept) : value;
                if (!concept) return;
                setMoment({
                  ...moment,
                  blueprint_learning_sub_concept_id: concept.sub_concept_id,
                });
                setIsDirty(true);
                setIsSelectingConcept(false);
              }}
              onTeachingStyleChange={(value) => {
                const style =
                  typeof value === "function"
                    ? value(moment?.learning_teaching_style ?? null)
                    : value;
                if (!moment || !style) return;
                setMoment({ ...moment, learning_teaching_style: style });
                setIsDirty(true);
              }}
              allMoments={moments ?? []}
            />
          </DetailCard>
        )}
        {moment.moment_type === TESTING && (
          <DetailCard title="Testing" id="testingDetail">
            <TestingMomentDetails
              moment={moment}
              story={story}
              syllabus={syllabus}
              setSyllabus={setSyllabus}
              selectedSyllabusConcept={selectedSyllabusConcept ?? null}
              isSelectingConcept={isSelectingConcept}
              setIsSelectingConcept={setIsSelectingConcept}
              onConceptChange={(value) => {
                const concept =
                  typeof value === "function" ? value(selectedSyllabusConcept) : value;
                if (!concept) return;
                setMoment({
                  ...moment,
                  blueprint_learning_sub_concept_id: concept.sub_concept_id,
                });
                setIsDirty(true);
                setIsSelectingConcept(false);
              }}
              onTestingStyleChange={(value) => {
                const style =
                  typeof value === "function"
                    ? value(moment?.learning_teaching_style ?? null)
                    : value;
                if (!moment || !style) return;
                setMoment({ ...moment, learning_teaching_style: style });
                setIsDirty(true);
              }}
              allMoments={moments ?? []}
              testingMaxMistakes={moment.testing_max_mistakes ?? 3}
              setTestingMaxMistakes={(value) => {
                const mistakes =
                  typeof value === "function" ? value(moment?.testing_max_mistakes ?? 3) : value;
                setMoment({ ...moment, testing_max_mistakes: mistakes });
                setIsDirty(true);
              }}
            />
          </DetailCard>
        )}
        {allMomentData.momentData.contextBlocks.length > 0 && (
          <DetailCard title="Context" id="context">
            <ContextMomentDetails
              contextBlocks={allMomentData.momentData.contextBlocks}
              contextBlockLinks={allMomentData.momentData.contextBlockLinks}
              storyId={story.id}
              onContextChange={handleContextCheckboxChange}
            />
          </DetailCard>
        )}
      </div>
    </div>
  );
}

export default MomentDetail;
