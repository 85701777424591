import { useSetRecoilState, useResetRecoilState, useRecoilValue } from "recoil";
import { supabase } from "@/vendor/supabaseClient";
import { useCreatePlayerStoriesAndMoments } from "@/states/storyState.tsx";
import { LOCALE_FRENCH } from "@/constants/constant.ts";
import { gameEngineModalState } from "@/states/ModalState.ts";
import { useSetupAudioContext } from "@/hooks/useSetupAudioContext.ts";
import { 
  isMomentCompleteState,
  isGameLostState, 
  isMomentStartedState,
  currentCharacterEmotionState,
  objectiveHasJustBeenCompletedState,
  momentElementsAtom
} from "@/states/momentState.tsx";
import { 
  audioDataQueueState, 
  isThinkingState,
  audioContextState,
  isPlayingAudioState,
  backgroundMusicState,
  gainNodeState,
  sourceNodeState,
  analyserBackgroundMusicNodeState,
  analyserDialogueNodeState,
  playedMomentsState
} from "@/states/audioDataState.ts";
import { useWebSocket } from "@/gameEngineService/WebSocketProvider.tsx";
import { useRecoilState } from "recoil";

export function useGameEngineModal() {
  const setModalState = useSetRecoilState(gameEngineModalState);
  const createStoryAndMoment = useCreatePlayerStoriesAndMoments();
  const setupAudioContext = useSetupAudioContext();
  const { disconnectWebSocket } = useWebSocket();
  
  // Get current values for all the audio-related state
  const [audioContext, setAudioContext] = useRecoilState(audioContextState);
  const sourceNode = useRecoilValue(sourceNodeState);
  const gainNode = useRecoilValue(gainNodeState);
  const analyserBackgroundMusic = useRecoilValue(analyserBackgroundMusicNodeState);
  const analyserDialogue = useRecoilValue(analyserDialogueNodeState);
  
  // Get and set audio queue and playback state
  const [, setAudioQueue] = useRecoilState(audioDataQueueState);
  const [, setIsPlayingAudio] = useRecoilState(isPlayingAudioState);
  
  // Reset functions for all game-related state
  const resetMomentComplete = useResetRecoilState(isMomentCompleteState);
  const resetGameLost = useResetRecoilState(isGameLostState);
  const resetMomentStarted = useResetRecoilState(isMomentStartedState);
  const resetCharacterEmotion = useResetRecoilState(currentCharacterEmotionState);
  const resetObjectiveCompleted = useResetRecoilState(objectiveHasJustBeenCompletedState);
  const resetMomentElements = useResetRecoilState(momentElementsAtom);
  const resetAudioQueue = useResetRecoilState(audioDataQueueState);
  const resetIsThinking = useResetRecoilState(isThinkingState);
  
  // Audio-specific reset functions
  const resetIsPlayingAudio = useResetRecoilState(isPlayingAudioState);
  const resetBackgroundMusic = useResetRecoilState(backgroundMusicState);
  const resetGainNode = useResetRecoilState(gainNodeState);
  const resetSourceNode = useResetRecoilState(sourceNodeState);
  const resetAnalyserBackgroundMusic = useResetRecoilState(analyserBackgroundMusicNodeState);
  const resetAnalyserDialogue = useResetRecoilState(analyserDialogueNodeState);
  const resetPlayedMoments = useResetRecoilState(playedMomentsState);

  // Function to immediately stop all audio playback
  const stopAllAudioPlayback = () => {
    console.log("🛑 Immediately stopping all audio playback...");
    
    // Clear the audio queue immediately to prevent more audio from playing
    setAudioQueue([]);
    
    // Set isPlayingAudio to false to prevent the dialogue manager from playing more audio
    setIsPlayingAudio(false);
    
    // Stop any currently playing audio source node
    if (sourceNode) {
      try {
        sourceNode.stop();
        console.log("✅ Background music stopped");
      } catch (error) {
        // Ignore errors if the source was already stopped
        console.log("Source node was already stopped or never started");
      }
    }
    
    // We can also suspend the audio context to immediately stop all audio
    if (audioContext && audioContext.state === "running") {
      audioContext.suspend()
        .then(() => console.log("✅ Audio context suspended"))
        .catch(err => console.error("❌ Error suspending audio context:", err));
    }
  };

  // Function to reset all game state
  const resetGameState = () => {
    console.log("🧹 Cleaning up game engine state...");
    
    // First, immediately stop all audio playback
    stopAllAudioPlayback();
    
    // Then disconnect WebSocket
    disconnectWebSocket();
    
    // Properly clean up audio context resources
    if (audioContext) {
      try {
        // Disconnect any audio nodes that might be connected
        if (gainNode) {
          console.log("🔌 Disconnecting gain node...");
          gainNode.disconnect();
        }
        
        if (analyserBackgroundMusic) {
          console.log("🔌 Disconnecting background music analyser node...");
          analyserBackgroundMusic.disconnect();
        }
        
        if (analyserDialogue) {
          console.log("🔌 Disconnecting dialogue analyser node...");
          analyserDialogue.disconnect();
        }
        
        // Close existing audio context
        if (audioContext.state !== "closed") {
          console.log("🔊 Closing existing AudioContext...");
          // Attempt to close - this might not always work in all browsers
          // AudioContext.close() is an async operation
          audioContext.close()
            .then(() => console.log("✅ AudioContext closed successfully"))
            .catch(err => console.error("❌ Error closing AudioContext:", err));
        }
      } catch (error) {
        console.error("Error cleaning up audio resources:", error);
      }
      
      // Set to null to ensure it gets recreated next time
      setAudioContext(null);
    }
    
    // Reset all audio-related state
    resetIsPlayingAudio();
    resetBackgroundMusic();
    resetGainNode();
    resetSourceNode();
    resetAnalyserBackgroundMusic();
    resetAnalyserDialogue();
    resetAudioQueue();
    resetPlayedMoments();
    
    // Reset all Recoil state atoms
    resetMomentComplete();
    resetGameLost();
    resetMomentStarted();
    resetCharacterEmotion();
    resetObjectiveCompleted();
    resetMomentElements();
    resetIsThinking();
    
    console.log("✅ Game engine state reset complete");
  };

  const handleMomentTest = async (storyId: string, momentId: string): Promise<void> => {
    console.log("🎮 Starting moment test setup...", { storyId, momentId });

    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) {
      console.error("❌ No user found - cannot proceed with moment test");
      return;
    }
    console.log("👤 User authenticated:", user.id);

    // Setup audio immediately after user interaction
    console.log("🔊 Initializing audio context...");
    await new Promise<void>((resolve) => {
      setupAudioContext(() => {
        console.log("✅ Audio context initialized or resumed");
        resolve();
      });
    });

    try {
      await createStoryAndMoment(
        {
          user_id: user.id,
          blueprint_story_id: storyId,
          language: LOCALE_FRENCH,
        },
        momentId
      );
      console.log("📚 Story and moment created successfully");

      // Finish by opening the modal
      setModalState({ isOpen: true, storyId });
      console.log("🔍 Game engine modal opened");
    } catch (error) {
      console.error("❌ Error setting up the moment test:", error);
    }
  };

  return {
    handleMomentTest,
    resetGameState,
    stopAllAudioPlayback,
  };
}
