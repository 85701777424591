import { Table, TableBody, TableCell, TableRow } from "../catalyst/table.tsx";
import { SpokableButton } from "./SpokableButton.tsx";
import React, { ReactNode, useState } from "react";
import { generateMediaFileName, getSupabaseImageUrl } from "../../utils/mediaUtil.ts";
import {
  CHARACTER_VISUALS_LIST,
  FILE_EXTENSION_IMAGE,
  FILE_EXTENSION_VIDEO,
  IMAGE_TYPE_CINEMATIC_VIDEO,
  IMAGE_TYPE_EMOTION_VIDEO,
  IMAGE_TYPE_EVENT_VIDEO,
  VISUAL_TYPE_IMAGE,
  VISUAL_TYPE_VIDEO,
  VisualItem,
} from "../../constants/constant.ts";
import { CardVideo } from "./Card.tsx";

interface SpokableImageCardProps {
  imageUrl: string;
  storyId: string;
  listItems: VisualItem[];
  whiteBackground?: boolean;
  hideDelete?: boolean;
  onDelete?: () => void;
}

interface MediaContainerProps {
  children: ReactNode;
}

// New component for rendering images with error handling
interface MediaImageProps {
  storyId: string;
  imageKey: string;
  imageUrl: string;
  onError: () => void;
  className?: string;
  isVideoFallback?: boolean;
}

const MediaImage: React.FC<MediaImageProps> = ({
  storyId,
  imageKey,
  imageUrl,
  onError,
  className = "w-full h-full object-cover",
  isVideoFallback = false,
}) => {
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
    onError();
  };

  let imageSrc;
  if (imageError) {
    imageSrc = "/404.jpg";
  } else {
    if (isVideoFallback) {
      // Convert video URL format to image URL format by replacing .mp4 with .jpg
      // First check if imageUrl ends with .mp4, if so replace it with .jpg
      let imageFileName = imageUrl;
      if (imageFileName.endsWith(FILE_EXTENSION_VIDEO)) {
        imageFileName = imageFileName.replace(FILE_EXTENSION_VIDEO, FILE_EXTENSION_IMAGE);
      }

      // Try standard format first
      imageSrc = getSupabaseImageUrl(storyId, imageFileName) || "";
    } else {
      // Regular image, use the key prefix
      imageSrc = getSupabaseImageUrl(storyId, imageKey + imageUrl) || "";
    }
  }

  return <img className={className} alt="Image" src={imageSrc} onError={handleImageError} />;
};

const VisualCard: React.FC<SpokableImageCardProps> = ({
  imageUrl,
  listItems,
  whiteBackground = false,
  hideDelete = false,
  onDelete,
  storyId,
}) => {
  const [selectedItem, setSelectedItem] = useState(listItems[0]);
  const [videoError, setVideoError] = useState(false);

  // Reset the component state to try loading everything again
  const resetState = () => {
    setVideoError(false);
  };

  const onClick = (item: VisualItem) => {
    // When selecting a new item, always reset the error states to try fresh loading
    resetState();
    setSelectedItem(item);

    if (item.type === VISUAL_TYPE_VIDEO) {
      // No need to create an unused videoUrl variable
      if (CHARACTER_VISUALS_LIST.find((visual) => visual.key === item.key)) {
        // Logic without assigning to unused variable
        generateMediaFileName(imageUrl, IMAGE_TYPE_EMOTION_VIDEO, item.key, true);
      } else if (item.key === IMAGE_TYPE_CINEMATIC_VIDEO) {
        generateMediaFileName(imageUrl, IMAGE_TYPE_CINEMATIC_VIDEO, item.key, true);
      } else if (item.key === IMAGE_TYPE_EVENT_VIDEO) {
        generateMediaFileName(imageUrl, IMAGE_TYPE_EVENT_VIDEO, item.key, true);
      }
    }
    // No need for the else block with unused imgSrc
  };

  const handleVideoError = () => {
    setVideoError(true);
  };

  const MediaContainer: React.FC<MediaContainerProps> = ({ children }) => (
    <div className="w-full h-80 flex items-center justify-center overflow-hidden bg-gray-200">
      {children}
    </div>
  );

  return (
    <div>
      <div className={`w-full ${whiteBackground ? "bg-white" : "bg-gray-100"} flex`}>
        <MediaContainer>
          {selectedItem && selectedItem.type == VISUAL_TYPE_IMAGE && (
            <MediaImage
              storyId={storyId}
              imageKey={selectedItem.key}
              imageUrl={imageUrl}
              onError={handleVideoError}
            />
          )}
          {selectedItem &&
            selectedItem.type == VISUAL_TYPE_VIDEO &&
            CHARACTER_VISUALS_LIST.find((item) => item.key === selectedItem.key) && (
              <div>
                {videoError ? (
                  <>
                    <MediaImage
                      storyId={storyId}
                      imageKey={selectedItem.key}
                      imageUrl={imageUrl}
                      onError={handleVideoError}
                      isVideoFallback={true}
                    />
                  </>
                ) : (
                  <CardVideo
                    className="w-full h-full object-cover"
                    src={
                      getSupabaseImageUrl(
                        storyId,
                        generateMediaFileName(
                          imageUrl,
                          IMAGE_TYPE_EMOTION_VIDEO,
                          selectedItem.key,
                          true,
                        ),
                      ) || ""
                    }
                    fallbackSrc="/404.jpg"
                    onVideoError={handleVideoError}
                  />
                )}
              </div>
            )}
          {selectedItem && selectedItem.key == IMAGE_TYPE_CINEMATIC_VIDEO && (
            <>
              {videoError ? (
                <>
                  <MediaImage
                    storyId={storyId}
                    imageKey={selectedItem.key}
                    imageUrl={imageUrl}
                    onError={handleVideoError}
                    isVideoFallback={true}
                  />
                </>
              ) : (
                <CardVideo
                  className="w-full h-full object-cover"
                  src={
                    getSupabaseImageUrl(
                      storyId,
                      generateMediaFileName(
                        imageUrl,
                        IMAGE_TYPE_CINEMATIC_VIDEO,
                        selectedItem.key,
                        true,
                      ),
                    ) || ""
                  }
                  fallbackSrc="/404.jpg"
                  onVideoError={handleVideoError}
                />
              )}
            </>
          )}
          {selectedItem && selectedItem.key == IMAGE_TYPE_EVENT_VIDEO && (
            <>
              {videoError ? (
                <>
                  <MediaImage
                    storyId={storyId}
                    imageKey={selectedItem.key}
                    imageUrl={imageUrl}
                    onError={handleVideoError}
                    isVideoFallback={true}
                  />
                </>
              ) : (
                <CardVideo
                  className="w-full h-full object-cover"
                  src={
                    getSupabaseImageUrl(
                      storyId,
                      generateMediaFileName(
                        imageUrl,
                        IMAGE_TYPE_EVENT_VIDEO,
                        selectedItem.key,
                        true,
                      ),
                    ) || ""
                  }
                  fallbackSrc="/404.jpg"
                  onVideoError={handleVideoError}
                />
              )}
            </>
          )}
        </MediaContainer>
        <div className="flex flex-col justify-between w-full h-80">
          <div className="overflow-y-auto flex-grow">
            <Table>
              <TableBody>
                {listItems.map((item) => (
                  <TableRow
                    key={item.key}
                    onClick={() => onClick(item)}
                    className={`cursor-pointer hover:bg-black hover:text-white ${
                      item.key == selectedItem.key
                        ? whiteBackground
                          ? "bg-gray-100"
                          : "bg-white"
                        : ""
                    }`}
                  >
                    <TableCell className="!pl-8">{item.value}</TableCell>
                    <TableCell className="text-right !pr-8"></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      {!hideDelete && onDelete && (
        <div className="flex gap-4 p-4">
          <SpokableButton color="light" onClick={onDelete}>
            Delete
          </SpokableButton>
        </div>
      )}
    </div>
  );
};

export default VisualCard;