import { Tables } from "@/types/database";
import FormField from "../FormField";
import DatabaseSelectInput from "../DatabaseSelectInput";
import { SpokableButton } from "../SpokableButton";
import CharacterImageCard from "../CharacterImageCard";
import AutoResizeTextArea from "../AutoResizeTextArea";

interface CharacterMomentDetailsProps {
  moment: Tables<"blueprint_moments">;
  onMomentChange: (moment: Tables<"blueprint_moments">) => void;
  setIsDirty: (isDirty: boolean) => void;
  characterImages: Tables<"blueprint_character_medias">[] | null;
  onCharacterSelect: (characterId: string) => void;
  onCreateCharacter: () => void;
  onEditCharacter: (characterId: string) => void;
}

export function CharacterMomentDetails({
  moment,
  onMomentChange,
  setIsDirty,
  characterImages,
  onCharacterSelect,
  onCreateCharacter,
  onEditCharacter
}: CharacterMomentDetailsProps) {
  return (
    <>
      <FormField>
        <DatabaseSelectInput
          table="blueprint_characters"
          keyColumn="id"
          labelColumn="name"
          storyId={moment.blueprint_story_id}
          value={moment.blueprint_character_id || ""}
          onChange={(value) => {
            onMomentChange({ ...moment, blueprint_character_id: value || "" });
            if (value) onCharacterSelect(value);
            setIsDirty(true);
          }}
          placeholder="Select a character"
        />

        <div className="mt-6">
          <SpokableButton color="light" onClick={onCreateCharacter}>
            Create New Character
          </SpokableButton>
          {moment.blueprint_character_id && (
            <SpokableButton
              className="ml-6"
              onClick={() => onEditCharacter(moment.blueprint_character_id || "")}
            >
              Edit
            </SpokableButton>
          )}
        </div>
      </FormField>

      <FormField>
        <ul>
          {characterImages?.map((image) => (
            <li key={image.id} className="mb-8">
              <CharacterImageCard
                storyId={moment.blueprint_story_id}
                image={image}
                hideDelete={true}
                whiteBackground={true}
              />
            </li>
          ))}
        </ul>
      </FormField>

      <FormField label="Starting Dialogue">
        <AutoResizeTextArea
          value={moment.starting_dialogue || ""}
          onChange={(e) => {
            onMomentChange({ ...moment, starting_dialogue: e });
            setIsDirty(true);
          }}
        />
      </FormField>
    </>
  );
} 