import { Tables } from "@/types/database";
import { Link } from "react-router-dom";
import { Checkbox, CheckboxField } from "../../catalyst/checkbox";
import { Description, Label } from "../../catalyst/fieldset";

interface ContextMomentDetailsProps {
  contextBlocks: Tables<"blueprint_context_blocks">[];
  contextBlockLinks: Tables<"blueprint_moment_context_links">[];
  storyId: string;
  onContextChange: (contextId: string, isChecked: boolean) => void;
}

export function ContextMomentDetails({
  contextBlocks,
  contextBlockLinks,
  storyId,
  onContextChange
}: ContextMomentDetailsProps) {
  return (
    <ul className="grid grid-cols-2 gap-4">
      {contextBlocks.map((context) => {
        const isLinked = contextBlockLinks.some(
          (link) => link.context_block_id === context.id
        );

        return (
          <li key={context.id}>
            <CheckboxField>
              <Checkbox
                name="discoverability"
                value="show_on_events_page"
                checked={isLinked}
                onChange={(isChecked) => onContextChange(context.id, isChecked)}
              />
              <Label>
                {context.context_block_name}{" "}
                <Link to={`/admin/contexts/${storyId}/${context.id}`}>(edit)</Link>
              </Label>
              <Description className="overflow-hidden text-ellipsis whitespace-nowrap">
                {context.context_block_description}
              </Description>
            </CheckboxField>
          </li>
        );
      })}
    </ul>
  );
} 