import { useCallback, useState } from "react";
import { 
  generateMediaFileName, 
  getSupabaseImageUrl, 
  doesObjectExistInStorage,
  removeFileExtension
} from "../../utils/mediaUtil";
import { 
  FILE_EXTENSION_IMAGE, 
  IMAGE_TYPE_CINEMATIC_VIDEO, 
  IMAGE_TYPE_EVENT_VIDEO,
  VISUAL_TYPE_IMAGE
} from "../../constants/constant.ts";

export const useGetBackgroundMediaUrl = (
  storyId: string,
  backgroundImages: string[] | null,
  isEvent: boolean,
  fallbackUrl?: string,
) => {
  const [backgroundMedia, setBackgroundMedia] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isVideo, setIsVideo] = useState(true);

  const fetchBackgroundMedia = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (backgroundImages && backgroundImages.length > 0) {
        console.log("backgroundImages", backgroundImages);
        const randomIndex = Math.floor(Math.random() * backgroundImages.length);
        const baseObjectUrl = backgroundImages[randomIndex];

        // Generate video filename
        let fileName = "";
        if (!isEvent) {
          fileName = generateMediaFileName(
            baseObjectUrl,
            IMAGE_TYPE_CINEMATIC_VIDEO,
            "",
            true,
          );
        } else {
          fileName = generateMediaFileName(
            baseObjectUrl,
            IMAGE_TYPE_EVENT_VIDEO,
            "",
            true,
          );
        }
        
        // Check if the video file exists
        const videoObjectPath = `${storyId}/${fileName}`;
        console.log("Checking if video exists:", videoObjectPath);
        const videoExists = await doesObjectExistInStorage(videoObjectPath);
        
        if (videoExists) {
          // Video exists, use it
          console.log("Video found, using video URL");
          const url = getSupabaseImageUrl(storyId, fileName);
          setBackgroundMedia(url || fallbackUrl || "");
          setIsVideo(true);
        } else {
          // Video doesn't exist, try to use image instead
          console.log("Video not found, attempting to use image URL");
          setIsVideo(false);
          
          // Convert video filename to image filename
          const baseFileName = removeFileExtension(baseObjectUrl);
          // Create image filename without video prefix
          const imageFileName = `${baseFileName}${FILE_EXTENSION_IMAGE}`;
          
          // Check if the image exists
          const imageObjectPath = `${storyId}/${imageFileName}`;
          console.log("Checking if image exists:", imageObjectPath);
          const imageExists = await doesObjectExistInStorage(imageObjectPath);
          
          if (imageExists) {
            // Image exists, use it
            console.log("Image found, using image URL");
            const url = getSupabaseImageUrl(storyId, imageFileName, VISUAL_TYPE_IMAGE);
            setBackgroundMedia(url || fallbackUrl || "");
          } else {
            // Neither video nor image exists, use fallback
            console.log("Neither video nor image found, using fallback URL");
            setBackgroundMedia(fallbackUrl || "");
            setError("Media not found");
          }
        }
      } else {
        setBackgroundMedia(fallbackUrl || "");
      }
    } catch (err) {
      console.error("Error fetching background media:", err);
      setError("Failed to fetch background media");
      setBackgroundMedia(fallbackUrl || "");
    } finally {
      setIsLoading(false);
    }
  }, [backgroundImages, isEvent, storyId, fallbackUrl]);

  return { backgroundMedia, isLoading, error, isVideo, fetchBackgroundMedia };
};
