import { Tables } from "@/types/database";
import { Input } from "../../catalyst/input";
import { capitalize } from "@/utils/stringUtil";
import FormField from "../FormField";
import AutoResizeTextArea from "../AutoResizeTextArea";
import SpokableListBox from "../SpokableListBox";
import DatabaseSelectInput from "../DatabaseSelectInput";
import { MOMENT_TYPES } from "@/constants/constant";

interface BasicMomentDetailsProps {
  moment: Tables<"blueprint_moments">;
  onMomentChange: (moment: Tables<"blueprint_moments">) => void;
  setIsDirty: (isDirty: boolean) => void;
}

export function BasicMomentDetails({ moment, onMomentChange, setIsDirty }: BasicMomentDetailsProps) {
  return (
    <>
      <FormField label="Moment Name">
        <Input
          type="text"
          id="moment_name"
          value={capitalize(moment.moment_name) || ""}
          onChange={(e) => {
            onMomentChange({ ...moment, moment_name: e.target.value });
            setIsDirty(true);
          }}
        />
      </FormField>

      <FormField label="Moment Setting">
        <AutoResizeTextArea
          value={moment.moment_setting || ""}
          onChange={(e) => {
            onMomentChange({ ...moment, moment_setting: e });
            setIsDirty(true);
          }}
        />
      </FormField>

      <FormField label="Moment Type">
        <SpokableListBox
          options={MOMENT_TYPES}
          value={MOMENT_TYPES.find((type) => type.key === moment.moment_type) || MOMENT_TYPES[0]}
          onChange={(selectedType) => {
            onMomentChange({ ...moment, moment_type: selectedType.key });
            setIsDirty(true);
          }}
        />
      </FormField>

      <FormField label="Belong to scene">
        <DatabaseSelectInput
          table="blueprint_scenes"
          keyColumn="id"
          labelColumn="name"
          value={moment.scene_id || ""}
          onChange={(value) => {
            onMomentChange({ ...moment, scene_id: value });
            setIsDirty(true);
          }}
          placeholder="Select a scene"
          addNullValueOption={false}
          storyId={moment.blueprint_story_id}
          storyIdColumn={"story_id"}
        />
      </FormField>
    </>
  );
} 