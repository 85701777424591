import { SpokableButton } from "../SpokableButton.tsx";

interface DetailHeaderProps {
  title: string;
  subtitle?: string;
  label?: string;
  imageUrls?: string[];
  errorMessage: string | null;
  onErrorDismiss: () => void;
  onSave: () => void;
  onCancel?: () => void;
  onRegenerate: () => void;
  extraButtons?: React.ReactNode;
}

export function DetailHeader({
  title,
  subtitle,
  label,
  imageUrls = [],
  errorMessage,
  onErrorDismiss,
  onSave,
  onCancel,
  onRegenerate,
  extraButtons,
}: DetailHeaderProps) {
  return (
    <div className="sticky top-0 backdrop-blur-xl z-10 bg-white/90 pb-4">
      {errorMessage && (
        <div
          className="rounded border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
          onClick={onErrorDismiss}
        >
          {errorMessage}
        </div>
      )}
      <div className="flex gap-6 items-stretch">
        {imageUrls.length > 0 && (
          <div className="flex flex-col justify-between">
            {imageUrls.map((url, index) => (
              <img
                key={index}
                alt={`${title} Image ${index + 1}`}
                className={`object-cover ${imageUrls.length === 1 ? "w-64 h-full" : "w-24 h-1/2"}`}
                src={url}
              />
            ))}
          </div>
        )}
        <div className="flex flex-col justify-between w-full">
          <div>
            {label && <div className="text-sm text-gray-500 mb-2">{label}</div>}
            <h1 className="text-5xl font-bold mb-2">{title}</h1>
            {subtitle && <div className="text-xl mb-4">{subtitle}</div>}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex gap-2">
              {extraButtons}
              <SpokableButton onClick={onSave}>Save</SpokableButton>
              {onCancel && <SpokableButton onClick={onCancel}>Back</SpokableButton>}
            </div>
            <div className="flex items-center gap-4">
              <SpokableButton color="light" onClick={onRegenerate}>
                Regenerate
              </SpokableButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
