import React, { ReactNode, useEffect, useState } from "react";
import { Tables } from "@/types/database.ts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ConceptItem, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import SyllabusSubConceptSelector from "@/components/admin/syllabusManagement/SyllabusSubConceptSelector.tsx";
import {
  getSyllabusFromStoryId,
  useSyllabusDatabase,
} from "@/components/admin/syllabusManagement/useSyllabusDatabase.ts";
import { SpokableButton } from "@/components/admin/SpokableButton";
import SyllabusEditor from "@/components/admin/syllabusManagement/SyllabusEditor";

interface StyleOption {
  key: string;
  value: string;
}

interface BaseSyllabusConceptSelectorProps {
  story: Tables<"blueprint_stories"> | null;
  syllabus: SyllabusOutput | null;
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>;
  selectedSyllabusConcept: ConceptItem | null | undefined;
  setSelectedSyllabusConcept: React.Dispatch<React.SetStateAction<ConceptItem | null | undefined>>;
  selectedStyle: string | null;
  setSelectedStyle: React.Dispatch<React.SetStateAction<string | null>>;
  allMoments: Tables<"blueprint_moments">[] | null;
  styleOptions: StyleOption[];
  headerTitle?: string;
  styleSelectorLabel?: string;
  stylePlaceholder?: string;
  children?: ReactNode;
  showOnlyCovered?: boolean;
}

const BaseSyllabusConceptSelector: React.FC<BaseSyllabusConceptSelectorProps> = ({
  story,
  selectedStyle,
  setSelectedStyle,
  selectedSyllabusConcept,
  setSelectedSyllabusConcept,
  syllabus,
  setSyllabus,
  allMoments,
  styleOptions,
  headerTitle = "Select a concept to teach",
  styleSelectorLabel = "Scene Generation Instructions",
  stylePlaceholder = "Choose a teaching style...",
  children,
  showOnlyCovered = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { upsertSyllabus } = useSyllabusDatabase();

  useEffect(() => {
    async function fetchSyllabus() {
      if (!story) return;
      const syllabus = await getSyllabusFromStoryId(story.id);
      if (syllabus) setSyllabus(syllabus);
    }

    fetchSyllabus();
  }, [story]);

  const handleSaveAndClose = async () => {
    if (!syllabus || !story) return;
    try {
      const success = await upsertSyllabus(syllabus, story.id);
      if (success) {
        setErrorMessage(null);
        setIsEditing(false);
      } else {
        setErrorMessage("Failed to save syllabus");
      }
    } catch (error) {
      setErrorMessage(`Error saving syllabus: ${error}`);
    }
  };

  if (isEditing && syllabus) {
    return (
      <div className="w-full max-w-4xl mx-auto p-6">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-2xl font-bold">Edit Syllabus</h3>
          <div className="flex gap-2">
            <SpokableButton onClick={handleSaveAndClose}>Save & Close</SpokableButton>
            <SpokableButton color="light" onClick={() => setIsEditing(false)}>
              Cancel
            </SpokableButton>
          </div>
        </div>
        {errorMessage && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded mb-4">
            {errorMessage}
          </div>
        )}
        <div className="border rounded-lg p-4 bg-white">
          <SyllabusEditor syllabus={syllabus} setSyllabus={setSyllabus} />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-4xl mx-auto p-6">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-2xl font-bold">{headerTitle}</h3>
        {syllabus && (
          <SpokableButton color="light" onClick={() => setIsEditing(true)}>
            Edit Syllabus
          </SpokableButton>
        )}
      </div>
      <div className="space-y-6">
        <div className="w-full">
          <label className="block text-sm font-medium mb-2">{styleSelectorLabel}</label>
          <Select
            onValueChange={(value) => setSelectedStyle(value)}
            value={selectedStyle || undefined}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={stylePlaceholder} />
            </SelectTrigger>
            <SelectContent>
              {styleOptions.map((style) => (
                <SelectItem key={style.key} value={style.key}>
                  {style.value}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Render optional children (like the numeric stepper) */}
        {children}

        <div className="w-full border-zinc-200 border rounded-lg p-4">
          {syllabus && (
            <SyllabusSubConceptSelector
              allMoments={allMoments}
              syllabus={syllabus}
              setSyllabus={setSyllabus}
              selectedSyllabusConcept={selectedSyllabusConcept}
              setSelectedSyllabusConcept={setSelectedSyllabusConcept}
              showOnlyCovered={showOnlyCovered}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseSyllabusConceptSelector;
