import { useState, useEffect, useRef } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "./SpokableButton.tsx";
import GenerateSound from "./generateSoundModal/GenerateSound.tsx";
import { popupOpenState, nestedPopupCountState } from "../../states/ModalState.ts";
import { useSetRecoilState, useRecoilState } from "recoil";

interface SoundGeneratorButtonProps {
  storyId: string;
  onUploadComplete: (fileName: string) => void;
  prompt: string | null;
}

export default function SoundGeneratorButton({
  storyId,
  onUploadComplete,
  prompt,
}: SoundGeneratorButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const setPopupOpen = useSetRecoilState(popupOpenState);
  const [nestedPopupCount, setNestedPopupCount] = useRecoilState(nestedPopupCountState);
  const dialogRef = useRef(null);

  const openPopup = () => {
    setIsOpen(true);
    setPopupOpen(true);
    setNestedPopupCount(prevCount => prevCount + 1);
  };

  const closePopup = () => {
    setIsOpen(false);
    setPopupOpen(false);
    setNestedPopupCount(prevCount => Math.max(0, prevCount - 1));
  };

  // Set/unset popup state when dialog opens/closes
  useEffect(() => {
    if (isOpen) {
      setPopupOpen(true);
      setNestedPopupCount(prevCount => prevCount + 1);
    }
    return () => {
      if (isOpen === false) {
        setNestedPopupCount(prevCount => Math.max(0, prevCount - 1));
        // Only clear popup state if this was the last modal
        if (nestedPopupCount <= 1) {
          setPopupOpen(false);
        }
      }
    };
  }, [isOpen, setPopupOpen, nestedPopupCount, setNestedPopupCount]);

  return (
    <>
      <SpokableButton
        onClick={openPopup}
      >
        <SparklesIcon className="mr-2" /> Generate
      </SpokableButton>

      <Dialog
        open={isOpen}
        onClose={closePopup}
        className="relative z-[999]"
        initialFocus={dialogRef}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" style={{ zIndex: 990 }} />
        <div 
          className="fixed inset-0 flex items-center justify-center p-4" 
          style={{ zIndex: 999 }}
          ref={dialogRef}
          tabIndex={-1}
        >
          <DialogPanel 
            className="w-2/3 max-h-[90vh] transform overflow-hidden rounded-md bg-white shadow-xl transition-all flex flex-col"
            style={{ pointerEvents: 'auto' }}
          >
            <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4">
              Generate Sound
            </DialogTitle>
            <GenerateSound
              soundPrompt={prompt}
              onSoundSelected={(soundUrl) => {
                onUploadComplete(soundUrl);
                closePopup();
              }}
              storyId={storyId}
            />
            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <SpokableButton onClick={closePopup} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
