import React, { useEffect, useState } from "react";
import {
  IMAGE_TYPE_CINEMATIC_VIDEO,
  IMAGE_TYPE_EVENT_VIDEO,
  MOMENT_VISUALS_LIST,
} from "@/constants/constant.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import { SparklesIcon } from "@heroicons/react/16/solid";
import FormField from "../FormField.tsx";
import { Checkbox, CheckboxField } from "../../catalyst/checkbox.tsx";
import { Label } from "../../catalyst/fieldset.tsx";
import AnimatedStatus from "../AnimatedStatus.tsx";
import { downloadFile, useUploadVideos } from "./videoUtils.ts";
import { EmotionVideoGallery } from "./EmotionVideoGallery.tsx";
import useMediaGenerationWebsocket, {
  MediaMessageType,
} from "@/components/admin/generateImagesModal/hooks/useMediaGenerationWebsocket.ts";
import {
  DTOMomentVideosCall,
  DTOMomentVideosResult,
  DTOVideoResult,
} from "@/types/fastApiMediaGenerationTypes.ts";
import { WebSocketMessage } from "@/hooks/useBaseWebSocket.ts";
import { fileToBase64 } from "@/utils/imageUtil.ts";
import AutoResizeTextArea from "@/components/admin/AutoResizeTextArea.tsx";

interface GenerateEmotionVideosProps {
  storyId: string;
  baseImageUrlWithStory: string | null;
  onVideosCompleted: (imageToBeSavedUrl: string) => void;
  eventPrompt: string;
  cinematicPrompt: string;
}

export const GenerateMomentVideos: React.FC<GenerateEmotionVideosProps> = ({
  baseImageUrlWithStory,
  storyId,
  onVideosCompleted,
  cinematicPrompt,
  eventPrompt,
}) => {
  const [bluePrintImageToBeSaved, setBluePrintImageToBeSaved] = useState<string | null>(null);
  const [cinematicStatus, setCinematicStatus] = useState("");
  const [eventStatus, setEventStatus] = useState("");
  const [baseImageFile, setBaseImageFile] = useState<File | null>(null);
  const [videoResults, setVideoResults] = useState<DTOVideoResult[]>([]);
  const { uploadVideos } = useUploadVideos(storyId);
  const ws = useMediaGenerationWebsocket();
  const [checkedVideoType, setCheckedVideoType] = useState<string[]>([
    IMAGE_TYPE_EVENT_VIDEO,
    IMAGE_TYPE_CINEMATIC_VIDEO,
  ]);

  const [finalCinematicPrompt, setFinalCinematicPrompt] = useState(cinematicPrompt);
  const [finalEventPrompt, setFinalEventPrompt] = useState(eventPrompt);

  useEffect(() => {
    ws.onReceive(MediaMessageType.GENERATE_MOMENT_VIDEO, (message) => {
      const imageResult: DTOMomentVideosResult = message.messageObject;
      if (imageResult.output) {
        const videos = [...videoResults, imageResult.output];
        setVideoResults([...videoResults, imageResult.output]);
        if (imageResult.status === "succeeded" && videos.length == checkedVideoType.length) {
          handleUpload(videos);
          setCinematicStatus("");
        }
      } else {
        if (imageResult.is_cinematic) {
          setCinematicStatus(Math.round(imageResult.progress) + "%");
        }
        if (imageResult.is_event) {
          setEventStatus(Math.round(imageResult.progress) + "%");
        }
      }
    });

    ws.onReceive(MediaMessageType.IMAGE_ERROR, (message: WebSocketMessage) => {
      console.log(message.messageObject);
      setCinematicStatus("Error");
      setEventStatus("Error");
    });
  }, [ws]);

  const handleVideoTypeSelectionChange = (videoType: string, isChecked: boolean) => {
    setCheckedVideoType((prevChecked) => {
      if (isChecked) {
        return [...prevChecked, videoType];
      } else {
        return prevChecked.filter((emotion) => emotion !== videoType);
      }
    });
  };

  useEffect(() => {
    downloadImage();
  }, [baseImageUrlWithStory]);

  async function downloadImage() {
    if (!baseImageUrlWithStory) return;

    const file = await downloadFile(baseImageUrlWithStory);
    if (!file) return;
    setBaseImageFile(file);
    setCinematicStatus("");
  }

  const handleGenerateMomentVideos = async () => {
    if (!baseImageFile) return;

    setCinematicStatus("Starting...");
    setEventStatus("Starting...");

    const baseParams: DTOMomentVideosCall = {
      image_base_64: await fileToBase64(baseImageFile),
      compute_event_video: checkedVideoType.includes(IMAGE_TYPE_EVENT_VIDEO),
      compute_cinematic_video: checkedVideoType.includes(IMAGE_TYPE_CINEMATIC_VIDEO),
      cinematic_prompt: finalCinematicPrompt,
      event_prompt: finalEventPrompt,
    };

    ws.send(MediaMessageType.GENERATE_MOMENT_VIDEO, baseParams);
  };

  const handleUpload = async (videos: DTOVideoResult[]) => {
    const baseImage = await uploadVideos(baseImageUrlWithStory, videos);
    if (baseImage) {
      setBluePrintImageToBeSaved(baseImage.image_url);
      setCinematicStatus("");
      setEventStatus("");
    }
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      <div className="space-y-4 mb-4">
        <ul className="grid grid-cols-2 gap-4 mb-4">
          {MOMENT_VISUALS_LIST.map((videoType) => (
            <li key={videoType.key}>
              <CheckboxField>
                <Checkbox
                  id={videoType.key}
                  checked={checkedVideoType.includes(videoType.key)}
                  onChange={(checked) =>
                    handleVideoTypeSelectionChange(videoType.key, checked as boolean)
                  }
                />
                <Label htmlFor={videoType.key} className="ml-2">
                  {videoType.value}
                </Label>
              </CheckboxField>
            </li>
          ))}
        </ul>
      </div>
      {checkedVideoType.includes(IMAGE_TYPE_CINEMATIC_VIDEO) && (
        <FormField label="Cinematic video...">
          <AutoResizeTextArea
            className="mb-4"
            value={finalCinematicPrompt}
            onChange={(e) => setFinalCinematicPrompt(e)}
            minNumberOfRows={1}
          />
        </FormField>
      )}
      {checkedVideoType.includes(IMAGE_TYPE_EVENT_VIDEO) && (
        <FormField label="Objectives completed video...">
          <AutoResizeTextArea
            className="mb-4"
            value={finalEventPrompt}
            onChange={(e) => setFinalEventPrompt(e)}
            minNumberOfRows={1}
          />
        </FormField>
      )}
      <FormField>
        <SpokableButton
          onClick={() => {
            handleGenerateMomentVideos();
          }}
          className="mr-4"
        >
          <SparklesIcon className="mr-2 mb-4" />
          Generate videos
        </SpokableButton>
      </FormField>
      <EmotionVideoGallery videoResults={videoResults} />
      <div className="mt-4">
        <AnimatedStatus status={cinematicStatus} />
        <AnimatedStatus status={eventStatus} />
      </div>
      {bluePrintImageToBeSaved && videoResults.length != 0 && (
        <SpokableButton
          className="align-bottom mt-12"
          onClick={() => onVideosCompleted(bluePrintImageToBeSaved)}
          disabled={videoResults.length === 0}
        >
          Add videos above
        </SpokableButton>
      )}
    </div>
  );
};
