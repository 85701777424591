import { useCallback, useState } from "react";
import {
  CHARACTER_EMOTION_NORMAL,
  FILE_EXTENSION_IMAGE,
  IMAGE_TYPE_EMOTION_VIDEO,
  VISUAL_TYPE_IMAGE,
} from "@/constants/constant.ts";
import {
  doesObjectExistInStorage,
  generateMediaFileName,
  getSupabaseImageUrl,
  removeFileExtension,
} from "@/utils/mediaUtil.ts";

export const useGetCharacterMediaUrl = (
  storyId: string,
  characterImages: string[] | null,
  characterEmotion: string,
  characterEmotionStrength: number,
  fallbackUrl?: string,
) => {
  const [characterMedia, setCharacterMedia] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isVideo, setIsVideo] = useState(true);

  const fetchCharacterMedia = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (characterImages && characterImages.length > 0) {
        console.log("characterImages", characterImages);
        const randomIndex = Math.floor(Math.random() * characterImages.length);
        const baseObjectUrl = characterImages[randomIndex];

        // Generate video filename first
        let fileName;
        if (characterEmotionStrength > 5) {
          fileName = generateMediaFileName(
            baseObjectUrl,
            IMAGE_TYPE_EMOTION_VIDEO,
            characterEmotion,
            true,
          );
        } else {
          fileName = generateMediaFileName(
            baseObjectUrl,
            IMAGE_TYPE_EMOTION_VIDEO,
            CHARACTER_EMOTION_NORMAL,
            true,
          );
        }

        // Check if the video file exists
        const videoObjectPath = `${storyId}/${fileName}`;
        console.log("Checking if video exists:", videoObjectPath);
        const videoExists = await doesObjectExistInStorage(videoObjectPath);

        if (videoExists) {
          // Video exists, use it
          console.log("Video found, using video URL");
          const url = getSupabaseImageUrl(storyId, fileName);
          setCharacterMedia(url || fallbackUrl || "");
          setIsVideo(true);
        } else {
          // Video doesn't exist, try to use image instead
          console.log("Video not found, attempting to use image URL");
          setIsVideo(false);

          // Convert video filename to image filename
          const baseFileName = removeFileExtension(baseObjectUrl);
          // Create image filename without video prefix
          const imageFileName = `${baseFileName}${FILE_EXTENSION_IMAGE}`;

          // Check if the image exists
          const imageObjectPath = `${storyId}/${imageFileName}`;
          console.log("Checking if image exists:", imageObjectPath);
          const imageExists = await doesObjectExistInStorage(imageObjectPath);

          if (imageExists) {
            // Image exists, use it
            console.log("Image found, using image URL");
            const url = getSupabaseImageUrl(storyId, imageFileName, VISUAL_TYPE_IMAGE);
            setCharacterMedia(url || fallbackUrl || "");
          } else {
            // Neither video nor image exists, use fallback
            console.log("Neither video nor image found, using fallback URL");
            setCharacterMedia(fallbackUrl || "");
            setError("Media not found");
          }
        }
      } else {
        setCharacterMedia(fallbackUrl || "");
      }
    } catch (err) {
      console.error("Error fetching character media:", err);
      setError("Failed to fetch character media");
      setCharacterMedia(fallbackUrl || "");
    } finally {
      setIsLoading(false);
    }
  }, [characterImages, characterEmotion, characterEmotionStrength, storyId, fallbackUrl]);

  return { characterMedia, isLoading, error, isVideo, fetchCharacterMedia };
};
