import { Card, CardContent, CardH1 } from "../Card.tsx";

interface DetailCardProps {
  title: string;
  id: string;
  children: React.ReactNode;
}

export function DetailCard({ title, id, children }: DetailCardProps) {
  return (
    <Card isFullWidth={true}>
      <CardContent>
        <CardH1 label={title} id={id} />
        {children}
      </CardContent>
    </Card>
  );
}
