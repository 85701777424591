import { Tables } from "@/types/database";
import { ConceptItem, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import SelectSyllabusConcept from "../generateMomentsModal/learning/SelectSyllabusConcept";
import { SpokableButton } from "../SpokableButton";
import { Dispatch, SetStateAction } from "react";

interface LearningMomentDetailsProps {
  moment: Tables<"blueprint_moments">;
  story: Tables<"blueprint_stories">;
  syllabus: SyllabusOutput | null;
  selectedSyllabusConcept: ConceptItem | null;
  isSelectingConcept: boolean;
  setIsSelectingConcept: (value: boolean) => void;
  onConceptChange: Dispatch<SetStateAction<ConceptItem | null | undefined>>;
  onTeachingStyleChange: Dispatch<SetStateAction<string | null>>;
  allMoments: Tables<"blueprint_moments">[];
  setSyllabus: Dispatch<SetStateAction<SyllabusOutput | null>>;
}

export function LearningMomentDetails({
  moment,
  story,
  syllabus,
  selectedSyllabusConcept,
  isSelectingConcept,
  setIsSelectingConcept,
  onConceptChange,
  onTeachingStyleChange,
  allMoments,
  setSyllabus
}: LearningMomentDetailsProps) {
  if (!syllabus || !selectedSyllabusConcept) {
    return (
      <div className="p-4 text-gray-500">
        No learning concept selected. Click "Change" to select one.
        <div className="mt-4">
          <SpokableButton color="light" onClick={() => setIsSelectingConcept(true)}>
            Change
          </SpokableButton>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="border rounded-lg p-4 bg-white">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h3 className="text-lg font-semibold">
              Concept: {selectedSyllabusConcept.name}
            </h3>
            <p>
              <span className="font-bold">Teaching Strategy:</span>{" "}
              {moment.learning_teaching_style}
            </p>
            <p className="text-sm text-gray-600">{selectedSyllabusConcept.noun}</p>
            <p className="text-sm text-gray-600">{selectedSyllabusConcept.verb}</p>
            <p className="text-sm text-gray-600">{selectedSyllabusConcept.adjective}</p>
          </div>
          <SpokableButton color="light" onClick={() => setIsSelectingConcept(true)}>
            Change
          </SpokableButton>
        </div>
        <div className="space-y-2 text-sm">
          <p>
            <span className="font-bold">Description:</span> {syllabus.subject}
          </p>
        </div>
      </div>

      <Dialog open={isSelectingConcept} onOpenChange={setIsSelectingConcept}>
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>Select Learning Concept</DialogTitle>
          </DialogHeader>
          <SelectSyllabusConcept
            story={story}
            syllabus={syllabus}
            setSyllabus={setSyllabus}
            selectedSyllabusConcept={selectedSyllabusConcept}
            setSelectedSyllabusConcept={onConceptChange}
            selectedTeachingStyle={moment.learning_teaching_style}
            setSelectedTeachingStyle={onTeachingStyleChange}
            allMoments={allMoments}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
} 