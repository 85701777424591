import React, { useEffect, useMemo, useState } from "react";
import { LoadingSpinner } from "../../LoadingSpinner.tsx";
import DynamicForm from "../../DynamicForm.tsx";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "../../SpokableButton.tsx";
import { MomentBaseOutput } from "@/types/moment_base_prompts_generated_types.ts";
import { Input } from "@/components/ui/input";

export interface BaseGenerateMomentProps {
  status: string;
  generatedMoment: MomentBaseOutput | null;
  setGeneratedMoment: React.Dispatch<React.SetStateAction<MomentBaseOutput | null>>;
  generateMoment: (payload: any) => void;
  createParams: () => any;
}

const BaseGenerateMoment: React.FC<BaseGenerateMomentProps> = ({
  status,
  generatedMoment,
  setGeneratedMoment,
  generateMoment,

  createParams,
}) => {
  const moment = useMemo(() => generatedMoment, [generatedMoment]);
  const [seed, setSeed] = useState<string>("");

  useEffect(() => {
    if (generatedMoment === null && status === "") {
      const params = createParams();
      params.seed = seed;
      generateMoment(params);
    }
  }, [generatedMoment, status, seed, createParams, generateMoment]);

  const handleRegenerate = () => {
    setGeneratedMoment(null);
  };

  const handleSeedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeed(e.target.value);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6 max-h-[70vh] flex flex-col">
      <div className="flex items-center justify-between mb-6 shrink-0">
        <h3 className="text-2xl font-bold">
          Moment
          <SpokableButton
            color="light"
            isIconButton={true}
            className="ml-2"
            onClick={handleRegenerate}
          >
            <ArrowPathIcon />
          </SpokableButton>
          <span className="text-sm ml-2">{status}</span>
        </h3>
      </div>

      <div className="mb-4 flex items-center">
        <label htmlFor="seed" className="mr-2 font-medium text-sm">Seed:</label>
        <Input
          id="seed"
          type="text"
          value={seed}
          onChange={handleSeedChange}
          placeholder="Enter a seed value"
          className="max-w-xs"
        />
      </div>

      {/* Scrollable content area */}
      <div className="flex-1 overflow-y-auto">
        <div className="space-y-6">
          {status !== "" && <LoadingSpinner />}
          {moment && (
            <div className="bg-white rounded-lg">
              <DynamicForm
                data={moment}
                setData={setGeneratedMoment}
                filterIds={true}
                filterDates={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseGenerateMoment;
