import { supabase, supabaseUrl } from "../vendor/supabaseClient.ts";
import {
  CHARACTER_EMOTION_WAITING,
  FILE_EXTENSION_IMAGE,
  FILE_EXTENSION_VIDEO,
  IMAGE_BUCKET_NAME,
  IMAGE_TYPE_CINEMATIC_VIDEO,
  IMAGE_TYPE_EMOTION_VIDEO,
  IMAGE_TYPE_EVENT_VIDEO,
  IMAGE_TYPE_ORIGINAL,
  SOUND_BUCKET_NAME,
  VISUAL_TYPE_IMAGE,
  VISUAL_TYPE_VIDEO,
} from "../constants/constant.ts";
import { sanitizeForURL } from "./stringUtil.ts";

export function getSupabaseImageUrl(
  storyId: string | null | undefined,
  imageName: string | null | undefined,
  type = VISUAL_TYPE_IMAGE,
  includeTimeStamp: boolean = false,
): string | null {
  if (!imageName) return null;
  if (type == VISUAL_TYPE_VIDEO)
    imageName = imageName.replace(FILE_EXTENSION_IMAGE, FILE_EXTENSION_VIDEO);

  return `${supabaseUrl}/storage/v1/object/public/image/${storyId}/${imageName}${includeTimeStamp ? "?T=" + Date.now() : ""}`;
}

export async function uploadToSupabaseData(
  storyId: string,
  blob: Blob,
  fileNameWithExtension: string,
  bucket: string = SOUND_BUCKET_NAME,
  addTimeStampOnFilename: boolean = true,
): Promise<string | null> {
  try {
    const filePath = `${storyId}/${addTimeStampOnFilename ? Date.now() + "-" : ""}${fileNameWithExtension}`;
    const { data, error } = await supabase.storage.from(bucket).upload(filePath, blob, {
      upsert: true,
      contentType: blob.type,
    });

    if (error) {
      console.error("Error uploading file:", error);
      return null;
    }

    if (data !== null) {
      return data.path;
    }
  } catch (error) {
    console.error("Error fetching file from URL:", error);
  }

  return null;
}

export function base64ToMp3Blob(base64: string): Blob {
  // Decode the Base64 string
  const binaryString: string = atob(base64);

  // Create a Uint8Array from the binary string
  const bytes: Uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob with the Uint8Array
  return new Blob([bytes], { type: "audio/mpeg" });
}

export function getImageNameWithoutStoryIdAndFileExtension(imageNameWithStoryId: string) {
  return imageNameWithStoryId.split("/").pop()?.split(".").shift();
}

export function getImageNameWithoutStoryId(imageNameWithStoryId: string) {
  return imageNameWithStoryId.split("/").pop();
}

export function getCustomEmotionVideoFileName(collectionName: string, emotionKey: string) {
  return `${sanitizeForURL(collectionName)}-${emotionKey}.mp4`;
}

export function removeFileExtension(fileName: string) {
  if (fileName && fileName.includes(".")) {
    const parts = fileName.split(".");
    fileName = parts.shift() || fileName;
  }
  return fileName;
}

export async function uploadToSupabaseFileFromUrl(
  storyId: string,
  fileUrl: string,
  fileName: string,
): Promise<string | null> {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const filePath = `${storyId}/${fileName}`;

    const { data, error } = await supabase.storage.from(IMAGE_BUCKET_NAME).upload(filePath, blob, {
      upsert: true,
    });

    if (error) {
      console.error("Error uploading file:", error);
      return null;
    }

    if (data !== null) {
      return data.path;
    }
  } catch (error) {
    console.error("Error fetching file from URL:", error);
  }

  return null;
}

export async function doesObjectExistInStorage(objectName: string) {
  const { data, error } = await supabase.storage.from(IMAGE_BUCKET_NAME).download(objectName); // The expiry time is set to 1 second

  if (error) {
    console.error("Error fetching signed URL:", error);
    return false;
  }

  return !!data;
}

export async function downloadImageFromSupabase(fileNameWithStory: string) {
  const { data, error } = await supabase.storage
    .from(IMAGE_BUCKET_NAME)
    .download(fileNameWithStory);

  if (error) {
    console.error("Error fetching signed URL:", error);
    return null;
  }

  return data;
}

export function generateMediaFileName(
  baseObjectUrl: string,
  fileType: string,
  emotionName: string = "",
  isVideo: boolean = false,
): string {
  const fileExtension = isVideo ? FILE_EXTENSION_VIDEO : FILE_EXTENSION_IMAGE;
  baseObjectUrl = removeFileExtension(baseObjectUrl);

  const prefix = fileType === IMAGE_TYPE_ORIGINAL ? "" : fileType;
  const emotion = fileType === IMAGE_TYPE_EMOTION_VIDEO ? emotionName : "";

  return `${prefix}${emotion}${baseObjectUrl}${fileExtension}`;
}

export async function copyFile(
  fromFilePath: string,
  toFilePath: string,
  fromBucket: string = IMAGE_BUCKET_NAME,
  toBucket: string = IMAGE_BUCKET_NAME,
): Promise<void> {
  try {
    // Step 1: Download the file from the original path
    const { data: originalFileData, error: downloadError } = await supabase.storage
      .from(fromBucket)
      .download(fromFilePath);

    if (downloadError) {
      throw new Error(`Failed to download file: ${downloadError.message}`);
    }

    if (!originalFileData) {
      throw new Error("Original file data is empty.");
    }

    // Step 2: Upload the file to the new path with a new name
    const { error: uploadError } = await supabase.storage
      .from(toBucket)
      .upload(toFilePath, originalFileData, {
        cacheControl: "3600", // Set cache control (optional)
        upsert: false, // Set to true to overwrite existing file
      });

    if (uploadError) {
      throw new Error(`Failed to upload file to new path: ${uploadError.message}`);
    }

    console.log("File copied successfully!");
  } catch (error) {
    console.error(error);
  }
}

export async function moveCharacterImageToMomentImage(fileName: string, storyId: string) {
  const { data } = await supabase
    .from("blueprint_characters")
    .select("*")
    .eq("blueprint_story_id", storyId);

  if (data) {
    const characterNames = data.map((character) => {
      return character.name;
    });
    characterNames.forEach((name) => {
      if (fileName.startsWith(name)) {
        const waitingVideoUrlName = generateMediaFileName(
          fileName,
          IMAGE_TYPE_EMOTION_VIDEO,
          CHARACTER_EMOTION_WAITING,
          true,
        );
        const fromObject = `${storyId}/${waitingVideoUrlName}`;
        const cinematicVideoUrlName = generateMediaFileName(
          fileName,
          IMAGE_TYPE_CINEMATIC_VIDEO,
          "",
          true,
        );
        const toObject = `${storyId}/${cinematicVideoUrlName}`;

        const eventVideoUrlName = generateMediaFileName(fileName, IMAGE_TYPE_EVENT_VIDEO, "", true);
        const toObject2 = `${storyId}/${eventVideoUrlName}`;

        copyFile(fromObject, toObject);
        copyFile(fromObject, toObject2);
      }
    });
  }
}
