// src/components/admin/storyGraph/sceneLevel/nodes/CraftingNode.tsx
import React from "react";
import { NodeProps } from "@xyflow/react";
import { Blend } from "lucide-react";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";
import { BaseMomentNode } from "@/components/admin/storyGraph/common/BaseMomentNode.tsx";

const CraftingNode: React.FC<NodeProps<MomentNode>> = (props) => {
  return (
    <BaseMomentNode
      nodeProps={props}
      borderColor="rgb(134 239 172)"
      icon={<Blend className="w-5 h-5 text-green-500" />}
    />
  );
};

export default CraftingNode;
