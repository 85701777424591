import React from "react";
import { DatabaseTypes } from "@/components/admin/promptManagement/database/databaseOperations";
import { DTOEvalResult } from "@/types/fastApiPromptManagerTypes";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/catalyst/table";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";

interface EvalResultsTableProps {
  evalResults: DTOEvalResult[];
  criterias: DatabaseTypes["Criteria"][];
  promptExecutionLogs: DatabaseTypes["PromptExecutionDetails"][];
  isPromptOverridden: boolean;
}

const EvalResultsTable: React.FC<EvalResultsTableProps> = ({
  evalResults,
  criterias,
  promptExecutionLogs,
  isPromptOverridden,
}) => {
  const resultMatrix = new Map<string, Map<string, DTOEvalResult>>();

  promptExecutionLogs.forEach((log) => {
    if (log.id) {
      resultMatrix.set(log.id, new Map());
    }
  });

  evalResults.forEach((result) => {
    const promptMap = resultMatrix.get(result.prompt_execution_id);
    if (promptMap) {
      promptMap.set(result.criteria_id, result);
    }
  });

  const findResultByLogId = (logId: string | null, criteriaId?: string): DTOEvalResult | undefined => {
    if (!logId) return undefined;
    
    // If criteriaId is provided, look for a specific result
    if (criteriaId) {
      // First try the matrix
      const promptMap = resultMatrix.get(logId);
      if (promptMap) {
        const result = promptMap.get(criteriaId);
        if (result) {
          return result;
        }
      }
      
      // Then try direct search
      return evalResults.find(r => 
        r.prompt_execution_id === logId && 
        r.criteria_id === criteriaId
      );
    }
    
    // If no criteriaId, just find any result for this logId
    return evalResults.find(result => result.prompt_execution_id === logId);
  };

  return (
    <div className="w-full overflow-x-auto max-w-full">
      <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)] w-full max-w-full table-fixed">
        <TableHead>
          <TableRow>
            <TableHeader className="w-[200px] max-w-[200px]">
              <HoverCard>
                <HoverCardTrigger asChild>
                  <span className="block truncate cursor-help">Dataset Input</span>
                </HoverCardTrigger>
                <HoverCardContent className="w-80">
                  <div className="flex justify-between space-x-4">
                    <div className="space-y-1">
                      <h4 className="text-sm font-semibold">Dataset Input</h4>
                      <p className="text-sm text-muted-foreground">
                        Input variables provided to the prompt
                      </p>
                    </div>
                  </div>
                </HoverCardContent>
              </HoverCard>
            </TableHeader>
            {criterias.map((criteria) => (
              <TableHeader key={criteria.id} className="w-[150px] max-w-[150px]">
                <HoverCard>
                  <HoverCardTrigger asChild>
                    <span className="block truncate cursor-help">
                      {criteria.eval_criteria_name}
                    </span>
                  </HoverCardTrigger>
                  <HoverCardContent className="w-80">
                    <div className="flex justify-between space-x-4">
                      <div className="space-y-1">
                        <h4 className="text-sm font-semibold">{criteria.eval_criteria_name}</h4>
                        <p className="text-sm text-muted-foreground whitespace-pre-wrap">
                          {criteria.criteria}
                        </p>
                      </div>
                    </div>
                  </HoverCardContent>
                </HoverCard>
              </TableHeader>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {promptExecutionLogs.map((log) => (
            <TableRow key={log.id}>
              <TableCell className="font-medium">
                <HoverCard>
                  <HoverCardTrigger asChild>
                    <span className="block truncate cursor-help">
                      {log.prompt_variable_inputs
                        ? (() => {
                            const inputStr = JSON.stringify(log.prompt_variable_inputs);
                            // Truncate to 50 characters max for display in the table cell
                            return inputStr.length > 50 
                              ? inputStr.substring(0, 47) + "..." 
                              : inputStr;
                          })()
                        : "No input data"}
                    </span>
                  </HoverCardTrigger>
                  <HoverCardContent className="w-[90vw] max-w-[90vw]">
                    <div className="space-y-1">
                      <h4 className="text-base font-semibold mb-2">Dataset Input</h4>
                      <pre className="font-sans bg-zinc-50 p-2 w-full max-w-full rounded-md overflow-auto max-h-[200px] whitespace-pre-wrap break-words text-sm">
                        {log.prompt_variable_inputs
                          ? JSON.stringify(log.prompt_variable_inputs, null, 2)
                          : "No input data"}
                      </pre>
                      <h4 className="text-base font-semibold mt-4 mb-2">Result</h4>
                      {isPromptOverridden ? (
                        <pre className="font-sans bg-zinc-50 p-2 w-full max-w-full rounded-md overflow-auto max-h-[300px] whitespace-pre-wrap break-words">
                          {(() => {
                            if (!log.id) return "No log ID available";
                            
                            // Get result for this log
                            const result = findResultByLogId(log.id);
                            
                            if (!result || !result.llm_answer) {
                              return "No result data available";
                            }
                            
                            try {
                              return JSON.stringify(JSON.parse(result.llm_answer), null, 2);
                            } catch (e: unknown) {
                              return `Error parsing result: ${e instanceof Error ? e.message : 'Unknown error'}`;
                            }
                          })()}
                        </pre>
                      ) : (
                        <pre className="font-sans bg-zinc-50 p-2 w-full max-w-full rounded-md overflow-auto max-h-[300px] whitespace-pre-wrap break-words">
                          {log.response
                            ? JSON.stringify(JSON.parse(log.response), null, 2)
                            : "No input data"}
                        </pre>
                      )}
                    </div>
                  </HoverCardContent>
                </HoverCard>
              </TableCell>
              {criterias.map((criteria) => {
                const result = findResultByLogId(log.id, criteria.id);
                
                return (
                  <TableCell key={criteria.id}>
                    {result ? (
                      <HoverCard>
                        <HoverCardTrigger asChild>
                          <div className="space-y-1 cursor-help">
                            <div className="flex items-center justify-between">
                              <span className="font-medium truncate">
                                Score: {result.eval_result.score}
                              </span>
                              <span
                                className={`
                                  px-2 py-1 rounded-full text-xs
                                  ${
                                    result.eval_result.score > 3
                                      ? "bg-green-100 text-green-800"
                                      : "bg-red-100 text-red-800"
                                  }
                                `}
                              >
                                {result.eval_result.score > 3 ? "Pass" : "Fail"}
                              </span>
                            </div>
                          </div>
                        </HoverCardTrigger>
                        <HoverCardContent className="w-80">
                          <div className="space-y-1">
                            <h4 className="text-sm font-semibold">Score Rationale</h4>
                            <p className="text-sm text-muted-foreground whitespace-pre-wrap">
                              {result.eval_result.score_rational}
                            </p>
                          </div>
                        </HoverCardContent>
                      </HoverCard>
                    ) : (
                      <span className="text-gray-400">No result for this criteria</span>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default EvalResultsTable;
