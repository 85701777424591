import { Tables } from "@/types/database";
import FormField from "../FormField";
import StorageFileSelector from "../StorageFileSelector";
import { IMAGE_BUCKET_NAME } from "@/constants/constant";
import MediaGeneratorMomentModalButton from "../MediaGeneratorMomentModalButton";
import MomentImageCard from "../MomentImageCard";

interface VisualsMomentDetailsProps {
  moment: Tables<"blueprint_moments">;
  story: Tables<"blueprint_stories">;
  momentImages: Tables<"blueprint_moment_medias">[] | null;
  onStorageFileSelected: (fileName: string) => void;
  onImagesRefresh: () => void;
}

export function VisualsMomentDetails({
  moment,
  story,
  momentImages,
  onStorageFileSelected,
  onImagesRefresh
}: VisualsMomentDetailsProps) {
  return (
    <>
      <FormField>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <StorageFileSelector
            bucketName={IMAGE_BUCKET_NAME}
            storyId={story.id}
            onStorageFileSelected={onStorageFileSelected}
            value={story.story_logo_image}
            hideImagePreview={true}
            useSimpleSelect={true}
            showUploader={false}
          />
          <MediaGeneratorMomentModalButton
            story={story}
            onUploadComplete={onImagesRefresh}
            basePrompt={moment.prompt_image_generation}
            promptModifier={story.image_prompt_style}
            baseFileName={moment.moment_name}
            momentId={moment.id}
            cinematicPrompt={moment.prompt_background_video_generation}
            eventPrompt={moment.prompt_event_video_generation}
            imageReferenceFileName={
              momentImages
                ? momentImages[Math.floor(Math.random() * momentImages.length)]?.media_url
                : story.background_image || null
            }
          />
        </div>
      </FormField>

      {momentImages && momentImages.length > 0 && (
        <FormField label="Selected Images:">
          <ul>
            {momentImages.map((image) => (
              <li key={image.id}>
                <MomentImageCard
                  storyId={moment.blueprint_story_id}
                  image={image}
                  whiteBackground={true}
                  onRefreshNeeded={onImagesRefresh}
                />
              </li>
            ))}
          </ul>
        </FormField>
      )}
    </>
  );
} 