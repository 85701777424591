import React from "react";
import { Tables } from "@/types/database.ts";
import { ConceptItem, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import { TEST_STYLES } from "@/constants/constant.ts";
import BaseSyllabusConceptSelector from "@/components/admin/generateMomentsModal/shared/BaseSyllabusConceptSelector";
import { Input } from "@/components/ui/input";
import { Minus, Plus } from "lucide-react";
import { SpokableButton } from "@/components/admin/SpokableButton";

interface SelectSyllabusConceptProps {
  story: Tables<"blueprint_stories"> | null;
  syllabus: SyllabusOutput | null;
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>;
  selectedSyllabusConcept: ConceptItem | null | undefined;
  setSelectedSyllabusConcept: React.Dispatch<React.SetStateAction<ConceptItem | null | undefined>>;
  selectedTestingStyle: string | null;
  setSelectedTestingStyle: React.Dispatch<React.SetStateAction<string | null>>;
  allMoments: Tables<"blueprint_moments">[] | null;
  testingMaxMistakes: number;
  setTestingMaxMistakes: React.Dispatch<React.SetStateAction<number>>;
}

const SelectTestingSyllabusConcept: React.FC<SelectSyllabusConceptProps> = ({
  story,
  selectedTestingStyle,
  setSelectedTestingStyle,
  selectedSyllabusConcept,
  setSelectedSyllabusConcept,
  syllabus,
  setSyllabus,
  allMoments,
  testingMaxMistakes,
  setTestingMaxMistakes,
}) => {
  // Handle value changes with validation
  const handleMaxMistakesChange = (value: number) => {
    const newValue = Math.max(1, Math.min(10, value)); // Limit between 1-10
    setTestingMaxMistakes(newValue);
  };

  // Create a numeric stepper component
  const NumericStepper = () => (
    <div className="w-full mt-6">
      <label className="block text-sm font-medium mb-2">Maximum Allowed Mistakes</label>
      <div className="flex items-cente gap-2">
        <SpokableButton
          color="light"
          isIconButton={true}
          onClick={() => handleMaxMistakesChange(testingMaxMistakes - 1)}
          disabled={testingMaxMistakes <= 1}
        >
          <Minus />
        </SpokableButton>
        <Input
          type="number"
          value={testingMaxMistakes}
          onChange={(e) => handleMaxMistakesChange(parseInt(e.target.value) || 1)}
          min={1}
          max={10}
          className="text-center w-20 rounded-none"
        />
        <SpokableButton
          color="light"
          isIconButton={true}
          onClick={() => handleMaxMistakesChange(testingMaxMistakes + 1)}
          disabled={testingMaxMistakes >= 10}
        >
          <Plus />
        </SpokableButton>
      </div>
    </div>
  );

  return (
    <BaseSyllabusConceptSelector
      story={story}
      selectedStyle={selectedTestingStyle}
      setSelectedStyle={setSelectedTestingStyle}
      selectedSyllabusConcept={selectedSyllabusConcept}
      setSelectedSyllabusConcept={setSelectedSyllabusConcept}
      syllabus={syllabus}
      setSyllabus={setSyllabus}
      allMoments={allMoments}
      styleOptions={TEST_STYLES}
      headerTitle="Select a concept to test"
      styleSelectorLabel="Testing Style"
      stylePlaceholder="Choose a testing style..."
      showOnlyCovered={true}
    >
      <NumericStepper />
    </BaseSyllabusConceptSelector>
  );
};

export default SelectTestingSyllabusConcept;
