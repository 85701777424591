import React from "react";
import { NodeProps } from "@xyflow/react";

import { MessageSquare } from "lucide-react";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";
import { BaseMomentNode } from "@/components/admin/storyGraph/common/BaseMomentNode.tsx";

const MonologueNode: React.FC<NodeProps<MomentNode>> = (props) => {
  return (
    <BaseMomentNode
      nodeProps={props}
      borderColor="rgb(229 255 118)"
      icon={<MessageSquare className="w-5 h-5" />}
    />
  );
};

export default MonologueNode;
