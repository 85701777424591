import { useEffect, useRef, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useSetRecoilState } from "recoil";
import { PencilIcon } from "@heroicons/react/16/solid";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { nestedPopupCountState, popupOpenState } from "@/states/ModalState.ts";
import { ImageModelCRUD } from "./ImageModelCRUD/ImageModelCRUD";

/**
 * A specialized modal button for the ImageModelCRUD component
 * This ensures proper z-index and focus handling for nested dialogs
 */
export default function ImageModelCRUDButton() {
  const [isOpen, setIsOpen] = useState(false);
  const setNestedPopupCount = useSetRecoilState(nestedPopupCountState);
  const setPopupOpen = useSetRecoilState(popupOpenState);
  const dialogRef = useRef<HTMLDivElement>(null);

  const openPopup = () => {
    setIsOpen(true);
    setPopupOpen(true);
    setNestedPopupCount((prevCount) => prevCount + 1);
  };

  const closePopup = () => {
    // Force close this dialog
    setIsOpen(false);
    setPopupOpen(false);
    setNestedPopupCount(0); // Reset completely
  };

  useEffect(() => {
    return () => {
      // Always clean up when component unmounts
      setNestedPopupCount(0);
      setPopupOpen(false);
    };
  }, [setNestedPopupCount, setPopupOpen]);

  // Handle popup state lifecycle
  useEffect(() => {
    if (isOpen) {
      setPopupOpen(true);
    }
  }, [isOpen, setPopupOpen]);

  return (
    <>
      <SpokableButton color="light" isIconButton={true} onClick={openPopup}>
        <PencilIcon />
      </SpokableButton>

      <Dialog
        open={isOpen}
        onClose={closePopup}
        className="relative z-[500]" // Higher than any other z-index in the system
        initialFocus={dialogRef}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" style={{ zIndex: 495 }} />
        <div
          className="fixed inset-0 flex items-center justify-center p-4"
          style={{ zIndex: 500 }}
          ref={dialogRef}
          tabIndex={-1}
        >
          <DialogPanel
            className="w-4/5 transform rounded-md bg-white shadow-xl transition-all"
            style={{
              pointerEvents: "auto",
              isolation: "isolate", // Creates a new stacking context
            }}
          >
            <DialogTitle className="font-serif p-6 text-4xl font-bold">
              Blueprint Models
            </DialogTitle>

            {/* Main content area - explicitly avoid interfering with scroll behaviors */}
            <div style={{ isolation: "isolate" }}>
              <ImageModelCRUD />
            </div>

            <div className="p-6 bg-gray-50 border-t">
              <div className="flex justify-between items-center">
                <SpokableButton onClick={closePopup} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
}
