import React from "react";
import { Tables } from "@/types/database.ts";
import { MONOLOGUE } from "@/components/admin/storyGraph/common/types.ts";
import { MomentBaseOutput } from "@/types/moment_base_prompts_generated_types.ts";
import BaseGenerateMoment from "../shared/BaseGenerateMoment";
import { MomentMonologueInput } from "@/types/moment_monologue_prompts_generated_types.ts";

export interface GenerateMomentProps {
  story: Tables<"blueprint_stories"> | null;
  status: string;
  generatedMoment: MomentBaseOutput | null;
  setGeneratedMoment: React.Dispatch<React.SetStateAction<MomentBaseOutput | null>>;
  generateMoment: (payload: any) => void;
  beatsheets: Tables<"blueprint_beatsheets">[];
  characters: Tables<"blueprint_characters">[];
  sceneBranch: Tables<"blueprint_branches"> | null | undefined;
  previousScenes: Tables<"blueprint_scenes">[] | null;
  previousMomentsInScene: Tables<"blueprint_moments">[] | null;
  scene: Tables<"blueprint_scenes"> | null | undefined;
}

const GenerateMonologueMoment: React.FC<GenerateMomentProps> = ({
  story,
  status,
  generatedMoment,
  setGeneratedMoment,
  generateMoment,
  beatsheets,
  characters,
  sceneBranch,
  previousScenes,
  previousMomentsInScene,
  scene,
}) => {
  const createParams = (): MomentMonologueInput => {
    return {
      one_pager: JSON.stringify(sceneBranch?.one_pager),
      character_list: JSON.stringify(characters),
      audience: story?.audience || "",
      previous_scenes: JSON.stringify(previousScenes),
      previous_beatsheets: JSON.stringify(beatsheets),
      previous_scene_moments: JSON.stringify(previousMomentsInScene),
      moment_type: MONOLOGUE,
      generated_moment_scene: JSON.stringify(scene),
      seed: "",
    };
  };

  return (
    <BaseGenerateMoment
      status={status}
      generatedMoment={generatedMoment}
      setGeneratedMoment={setGeneratedMoment}
      generateMoment={generateMoment}
      createParams={createParams}
    />
  );
};

export default GenerateMonologueMoment;
