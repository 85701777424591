import React from "react";
import { Tables } from "@/types/database.ts";
import { ConceptItem, SyllabusOutput } from "@/types/learning_concept_prompts_generated_types.ts";
import { TEACHING_STYLES } from "@/constants/constant.ts";
import BaseSyllabusConceptSelector from "@/components/admin/generateMomentsModal/shared/BaseSyllabusConceptSelector";

interface SelectSyllabusConceptProps {
  story: Tables<"blueprint_stories"> | null;
  syllabus: SyllabusOutput | null;
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>;
  selectedSyllabusConcept: ConceptItem | null | undefined;
  setSelectedSyllabusConcept: React.Dispatch<React.SetStateAction<ConceptItem | null | undefined>>;
  selectedTeachingStyle: string | null;
  setSelectedTeachingStyle: React.Dispatch<React.SetStateAction<string | null>>;
  allMoments: Tables<"blueprint_moments">[] | null;
}

const SelectSyllabusConcept: React.FC<SelectSyllabusConceptProps> = ({
  story,
  selectedTeachingStyle,
  setSelectedTeachingStyle,
  selectedSyllabusConcept,
  setSelectedSyllabusConcept,
  syllabus,
  setSyllabus,
  allMoments,
}) => {
  return (
    <BaseSyllabusConceptSelector
      story={story}
      selectedStyle={selectedTeachingStyle}
      setSelectedStyle={setSelectedTeachingStyle}
      selectedSyllabusConcept={selectedSyllabusConcept}
      setSelectedSyllabusConcept={setSelectedSyllabusConcept}
      syllabus={syllabus}
      setSyllabus={setSyllabus}
      allMoments={allMoments}
      styleOptions={TEACHING_STYLES}
      headerTitle="Select a concept to teach"
      styleSelectorLabel="Scene Generation Instructions"
      stylePlaceholder="Choose a teaching style..."
    />
  );
};

export default SelectSyllabusConcept;
