import React from "react";
import {
  ArrowBigUp,
  Blend,
  BookCheck,
  BookOpen,
  DoorOpen,
  MessageCircleCode,
  MessageSquare,
  Repeat2,
  Sword,
} from "lucide-react";
import {
  BONUS,
  CRAFTING,
  GATE_MOMENT,
  LEARNING,
  MONOLOGUE,
  MONOLOGUE_INSTRUCT,
  NEXUS_MOMENT,
  OBJECT_GENERATION,
  SceneNode,
  TESTING,
} from "@/components/admin/storyGraph/common/types";
import DraggableCard from "@/components/admin/storyGraph/common/DraggableCard.tsx";
import { ViewState } from "../StoryGraphContainer";

interface SceneSidebarProps {
  onDragStart: (event: React.DragEvent<HTMLDivElement>, nodeType: string) => void;
  currentView: ViewState;
  setCurrentView: (view: ViewState) => void;
  setSelectedSceneNode: React.Dispatch<React.SetStateAction<SceneNode | null>>;
}

const MomentSidebar: React.FC<SceneSidebarProps> = ({
  onDragStart,
  currentView,
  setCurrentView,
  setSelectedSceneNode,
}) => {
  const cards = [
    { icon: BookOpen, label: "Learn", nodeType: LEARNING },
    { icon: BookCheck, label: "Test", nodeType: TESTING },
    { icon: Blend, label: "Craft", nodeType: CRAFTING },
    { icon: ArrowBigUp, label: "Upgrade", nodeType: BONUS },
    { icon: DoorOpen, label: "Gate", nodeType: GATE_MOMENT },
    { icon: MessageSquare, label: "Monologue", nodeType: MONOLOGUE },
    { icon: MessageCircleCode, label: "Dynamic", nodeType: MONOLOGUE_INSTRUCT },
    { icon: Sword, label: "Objet", nodeType: OBJECT_GENERATION },
    { icon: Repeat2, label: "Nexus", nodeType: NEXUS_MOMENT },
  ];

  const navigateToSceneView = () => {
    setSelectedSceneNode(null);
    setCurrentView({ type: "scene" });
  };

  return (
    <div className="w-1/5 px-6 pt-6 border-r">
      <div className="py-4">
        <button
          onClick={() => navigateToSceneView()}
          className={`px-2 py-1 rounded hover:bg-gray-200`}
        >
          Scene
        </button>
        <span>/</span>
        <span className="">
          {" " + currentView.scene?.name || ` Scene ${currentView.scene?.id}`}
        </span>
      </div>
      <div className="space-y-4">
        {cards.map((card) => (
          <DraggableCard
            key={card.nodeType}
            icon={card.icon}
            label={card.label}
            nodeType={card.nodeType}
            onDragStart={onDragStart}
          />
        ))}
      </div>
    </div>
  );
};

export default MomentSidebar;
