import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { popupOpenState, nestedPopupCountState } from "@/states/ModalState.ts";

interface ModalButtonProps {
  children: React.ReactNode;
  buttonLabel?: string;
  modalTitle?: string | null;
  className?: string;
  trigger?: React.ReactNode;
  icon: React.ReactNode | null;
  buttonStyleLight?: boolean;
  onClose?: () => void;
  isIconButton?: boolean;
}

const ModalButton = ({
  children,
  buttonLabel,
  modalTitle = null,
  className = "",
  trigger,
  icon = null,
  buttonStyleLight = false,
  onClose,
  isIconButton = false,
}: ModalButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const setPopupOpen = useSetRecoilState(popupOpenState);
  const [nestedPopupCount, setNestedPopupCount] = useRecoilState(nestedPopupCountState);
  const dialogRef = useRef<HTMLDivElement>(null);

  const openPopup = () => {
    setIsOpen(true);
    setPopupOpen(true);
    setNestedPopupCount(prevCount => prevCount + 1);
  };

  const closePopup = () => {
    // Only actually close if this is the top-most modal (no child modals open)
    if (nestedPopupCount <= 1) {
      setIsOpen(false);
      setPopupOpen(false);
      setNestedPopupCount(0);
      if (onClose) onClose();
    }
  };

  // Handle popup state lifecycle
  useEffect(() => {
    if (isOpen) {
      setPopupOpen(true);
      setNestedPopupCount(prevCount => prevCount + 1);
    }
    return () => {
      if (!isOpen) {
        setNestedPopupCount(prevCount => Math.max(0, prevCount - 1));
        // Only clear popup state if this was the last modal
        if (nestedPopupCount <= 1) {
          setPopupOpen(false);
        }
      }
    };
  }, [isOpen, setPopupOpen, nestedPopupCount, setNestedPopupCount]);

  return (
    <>
      {trigger ? (
        <span onClick={openPopup}>
          {trigger}
        </span>
      ) : (
        <SpokableButton
          color={buttonStyleLight ? "light" : "dark/zinc"}
          isIconButton={isIconButton}
          onClick={openPopup}
        >
          {icon} {buttonLabel}
        </SpokableButton>
      )}

      <Dialog
        open={isOpen}
        onClose={closePopup}
        className="relative z-[400]"
        initialFocus={dialogRef}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" style={{ zIndex: 390 }} />
        <div 
          className="fixed inset-0 flex items-center justify-center p-4" 
          style={{ zIndex: 400 }}
          ref={dialogRef}
          tabIndex={-1}
        >
          <DialogPanel
            className={`${className} w-4/5 max-h-[90vh] transform rounded-md bg-white shadow-xl transition-all flex flex-col`}
            style={{ pointerEvents: 'auto' }}
          >
            {modalTitle && (
              <DialogTitle className="font-serif p-6 text-4xl font-bold mb-4 flex-none">
                {modalTitle}
              </DialogTitle>
            )}
            <div className="flex-1 overflow-auto">
              {React.cloneElement(children as React.ReactElement, {
                onClose: closePopup,
              })}
            </div>
            <div className="p-6 bg-gray-50 border-t flex-none">
              <div className="flex justify-between items-center">
                <SpokableButton onClick={closePopup} color="light">
                  Close
                </SpokableButton>
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default ModalButton;
