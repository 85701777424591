import React, { ReactNode } from "react";
import { NodeProps } from "@xyflow/react";
import { BaseNode } from "@/components/admin/storyGraph/common/BaseNode.tsx";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";

interface BaseMomentNodeProps {
  icon: ReactNode;
  borderColor?: string;
  nodeProps: NodeProps<MomentNode>;
  typeLabel?: string; // Optional custom type label
}

// Helper to get pill colors based on border color
const getPillColors = (borderColor?: string) => {
  // Default gray color if no border color is provided
  if (!borderColor) {
    return { bg: "bg-gray-100", text: "text-gray-800" };
  }
  
  // For the standard Tailwind RGB format like "rgb(147 197 253)" (blue-300)
  if (borderColor.startsWith("rgb(")) {
    // For blue borders (like the blue-300/blue-400 colors)
    if (borderColor.includes("147 197 253")) {
      return { bg: "bg-blue-100", text: "text-blue-800" };
    }
    
    // For green borders (like the green-300 color)
    if (borderColor.includes("134 239 172")) {
      return { bg: "bg-green-100", text: "text-green-800" };
    }
    
    // For yellow borders (monologue nodes)
    if (borderColor.includes("229 255 118")) {
      return { bg: "bg-yellow-100", text: "text-yellow-800" };
    }
    
    // For purple borders (gate and nexus nodes)
    if (borderColor.includes("216 180 254")) {
      return { bg: "bg-purple-100", text: "text-purple-800" };
    }
  }
  
  // Default fallback
  return { bg: "bg-gray-100", text: "text-gray-800" };
};

export const BaseMomentNode: React.FC<BaseMomentNodeProps> = ({ 
  icon, 
  borderColor = "rgb(229 231 235)", // Default gray border
  nodeProps, 
  typeLabel 
}) => {
  // Use the provided type label or extract from data.type
  const displayTypeLabel = typeLabel || nodeProps.data.type;
  
  // Get pill colors based on the border color
  const { bg, text } = getPillColors(borderColor);
  
  return (
    <BaseNode {...nodeProps} borderColor={borderColor}>
      <div className="flex flex-col gap-3">
        <div className="flex items-center justify-center gap-2">
          {icon}
          <span>{nodeProps.data.label}</span>
        </div>
        <div className="flex justify-end">
          <span className={`text-xs font-medium px-2.5 py-0.5 rounded-full ${bg} ${text}`}>
            {displayTypeLabel}
          </span>
        </div>
      </div>
    </BaseNode>
  );
}; 