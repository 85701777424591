export const MOMENT_TYPE_INTERACTIVE = "interactive";
export const MOMENT_TYPE_MONOLOGUE = "monologue";

export const MOMENT_TYPE_WON_GAME = "won_game";
export const MOMENT_TYPES = [
  { key: MOMENT_TYPE_INTERACTIVE, value: "Interactive" },
  { key: MOMENT_TYPE_MONOLOGUE, value: "Monologue" },
  { key: MOMENT_TYPE_WON_GAME, value: "Last Moment (won)" },
];

export const STORY_INTERACTIVITY_LOW = "Low Interactivity";
export const STORY_INTERACTIVITY_MEDIUM = "Medium Interactivity";
export const STORY_INTERACTIVITY_HIGH = "High Interactivity";
export const STORY_INTERACTIVITY_LEVELS = [
  { key: STORY_INTERACTIVITY_LOW, value: "Low Interactivity" },
  { key: STORY_INTERACTIVITY_MEDIUM, value: "Medium Interactivity" },
  { key: STORY_INTERACTIVITY_HIGH, value: "High Interactivity" },
];

export const STORY_BRANCHING_LOW = "Low Branching";
export const STORY_BRANCHING_MEDIUM = "Medium Branching";
export const STORY_BRANCHING_HIGH = "High Branching";
export const STORY_BRANCHING_LEVELS = [
  { key: STORY_BRANCHING_LOW, value: "Low Branching" },
  { key: STORY_BRANCHING_MEDIUM, value: "Medium Branching" },
  { key: STORY_BRANCHING_HIGH, value: "High Branching" },
];

export const IMAGE_TYPE_WITHOUT_BG = "NoBackground";
export const IMAGE_TYPE_DEPTH_MAP = "DepthMap";
export const IMAGE_TYPE_ORIGINAL = "Original";
export const IMAGE_TYPE_VIDEO = "Video";
export const IMAGE_TYPE_EMOTION_VIDEO = "EmotionVideo";
export const IMAGE_TYPE_CINEMATIC_VIDEO = "CinematicVideo";
export const IMAGE_TYPE_EVENT_VIDEO = "EventVideo";

export const VISUAL_TYPE_VIDEO = "video";
export const VISUAL_TYPE_IMAGE = "image";

export type VisualItem = {
  key: string;
  value: string;
  type: string;
};
export const CHARACTER_EMOTION_DEFAULT = "";
export const CHARACTER_EMOTION_NORMAL = "normal";
export const CHARACTER_EMOTION_HAPPINESS = "happiness";
export const CHARACTER_EMOTION_SADNESS = "sadness";
export const CHARACTER_EMOTION_ANGER = "anger";
export const CHARACTER_EMOTION_FEAR = "fear";
export const CHARACTER_EMOTION_DISGUST = "disgust";
export const CHARACTER_EMOTION_SURPRISE = "surprise";
export const CHARACTER_EMOTION_WAITING = "waiting";

export const CHARACTER_VISUALS_LIST: VisualItem[] = [
  { key: CHARACTER_EMOTION_NORMAL, value: "Normal", type: VISUAL_TYPE_VIDEO },
  { key: CHARACTER_EMOTION_HAPPINESS, value: "Happiness", type: VISUAL_TYPE_VIDEO },
  { key: CHARACTER_EMOTION_SADNESS, value: "Sadness", type: VISUAL_TYPE_VIDEO },
  { key: CHARACTER_EMOTION_ANGER, value: "Anger", type: VISUAL_TYPE_VIDEO },
  { key: CHARACTER_EMOTION_FEAR, value: "Fear", type: VISUAL_TYPE_VIDEO },
  { key: CHARACTER_EMOTION_DISGUST, value: "Disgust", type: VISUAL_TYPE_VIDEO },
  { key: CHARACTER_EMOTION_SURPRISE, value: "Surprise", type: VISUAL_TYPE_VIDEO },
];

export const MOMENT_VISUALS_LIST: VisualItem[] = [
  { key: IMAGE_TYPE_CINEMATIC_VIDEO, value: "Cinematic", type: VISUAL_TYPE_VIDEO },
  { key: IMAGE_TYPE_EVENT_VIDEO, value: "Objective completed", type: VISUAL_TYPE_VIDEO },
];

export const FILE_EXTENSION_IMAGE = ".jpg";
export const FILE_EXTENSION_VIDEO = ".mp4";
export const IMAGE_VARIATION_ENDPOINT = "process_image_variations";

export const SOUND_BUCKET_NAME = "sound";
export const IMAGE_BUCKET_NAME = "image";

export const LOCALE_FRENCH = "french";
export const LOCALE_ENGLISH = "english";

export const TAB = "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0";

export const PAGE_FLOW = "Flow";
export const PAGE_GRAPH = "Graph";
export const PAGE_GENERAL = "General";
export const PAGE_STORY = "Story";
export const PAGE_STORY_WIZARD = "Welcome";
export const PAGE_CONTEXT = "Contexts";
export const PAGE_CHARACTER = "Characters";
export const BRANCH_NAME_MAIN = "Main";

export const DEFAULT_IMAGE_GEN_KEY = "black-forest-labs/flux-schnell";
export const IMAGE_MODEL_CATEGORY_IMAGE_GEN = "Image Gen";
export const IMAGE_MODEL_CATEGORY_INPAINT = "Inpainting";
export const IMAGE_MODEL_UPSCALING = "Upscaling";

export const TEACHING_STYLE_ANALOGY = "analogy"; // Explains through comparisons
export const TEACHING_STYLE_SOCRATIC = "socratic"; // Leads through questions
export const TEACHING_STYLE_TOPDOWN = "topdown"; // Direct explanation
export const TEACHING_STYLE_AGE_BASED = "age-based"; // Adapts to specified age
export const TEACHING_STYLE_LECTURE = "lecture"; // Formal presentation

export const TEACHING_STYLES = [
  { key: TEACHING_STYLE_ANALOGY, value: "Analogy" },
  { key: TEACHING_STYLE_SOCRATIC, value: "Socratic" },
  { key: TEACHING_STYLE_TOPDOWN, value: "Top-down" },
  { key: TEACHING_STYLE_AGE_BASED, value: "Age-based" },
  { key: TEACHING_STYLE_LECTURE, value: "Lecture" },
];

export type TeachingStyle =
  | typeof TEACHING_STYLE_ANALOGY
  | typeof TEACHING_STYLE_SOCRATIC
  | typeof TEACHING_STYLE_TOPDOWN
  | typeof TEACHING_STYLE_AGE_BASED
  | typeof TEACHING_STYLE_LECTURE;

export const TEST_STYLE_CONVINCE = "convince"; // Change beliefs through demonstration
export const TEST_STYLE_SOLVE = "solve"; // Use logical deduction
export const TEST_STYLE_REVEAL = "reveal"; // Uncover hidden truth
export const TEST_STYLE_TEACH = "teach"; // Clarify complex concepts
export const TEST_STYLE_PROGRAM = "program"; // Configure actions/systems

export const TEST_STYLES = [
  { key: TEST_STYLE_CONVINCE, value: "Convince" },
  { key: TEST_STYLE_SOLVE, value: "Solve" },
  { key: TEST_STYLE_REVEAL, value: "Reveal" },
  { key: TEST_STYLE_TEACH, value: "Teach" },
  { key: TEST_STYLE_PROGRAM, value: "Program" },
];

export type TestStyle =
  | typeof TEST_STYLE_CONVINCE
  | typeof TEST_STYLE_SOLVE
  | typeof TEST_STYLE_REVEAL
  | typeof TEST_STYLE_TEACH
  | typeof TEST_STYLE_PROGRAM;
