import { useEffect, useState } from "react";
import { useGenerateSteps } from "@/components/admin/generateStory/useGenerateSteps.ts";
import AudienceBuilder from "@/components/admin/generateStory/AudienceBuilder.tsx";
import GenerateStoryIdeas from "@/components/admin/generateStory/GenerateStoryIdeas.tsx";
import GenerateLogLine from "@/components/admin/generateStory/GenerateLogLine.tsx";
import GenerateCharacters from "@/components/admin/generateStory/GenerateCharacters.tsx";
import { Card, CardContent } from "@/components/admin/Card.tsx";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import SaveAll from "@/components/admin/generateStory/SaveAll.tsx";
import GenerateOnePager from "@/components/admin/generateStory/GenerateOnePager.tsx";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "@/vendor/supabaseClient.ts";
import { Tables } from "@/types/database.ts";
import { GlobalSideNavLayout } from "@/components/admin/GlobalSideNavLayout.tsx";
import { PAGE_STORY_WIZARD } from "@/constants/constant.ts";
import GenerateStyle from "@/components/admin/generateStory/GenerateStyle.tsx";
import GenerateLearningIdeas from "@/components/admin/generateStory/GenerateLearningIdeas.tsx";
import GenerateLearningSyllabus from "@/components/admin/generateStory/GenerateLearningSyllabus.tsx";

const StoryWizardPage = () => {
  const { storyId } = useParams<{
    storyId: string;
  }>();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    stepIndex,
    setStepIndex,
    selectedIdea,
    setSelectedIdea,
    learningConcepts,
    learningConceptStatus,
    generateLearningConcept,
    setLearningConcept,
    selectedLearningConcept,
    setSelectedLearningConcept,
    syllabus,
    syllabusStatus,
    generateSyllabus,
    setSyllabus,
    ideas,
    setIdeas,
    ideasStatus,
    generateIdeas,
    logLine,
    logLineStatus,
    generateLogLine,
    setLogLine,
    onePager,
    onePagerStatus,
    generateOnePager,
    setOnePager,
    charactersSummary,
    charactersSummaryStatus,
    setCharactersSummary,
    generateCharactersSummary,
  } = useGenerateSteps();

  const maxStepIndex = 8;
  const [audience, setAudience] = useState("");
  const [story, setStory] = useState<Tables<"blueprint_stories"> | null>(null);

  const stepTitles = [
    "Define Audience",
    "Let's start with what we will learn...",
    "Curriculum",
    "Now, let's talk stories",
    "The pitch",
    "Everything in one page",
    "How does it look?",
    "The cast",
    "That's ready!",
  ];

  useEffect(() => {
    fetchStory();
  }, []);

  async function fetchStory() {
    if (storyId == undefined) return;
    const { data, error } = await supabase
      .from("blueprint_stories")
      .select("*")
      .eq("id", storyId)
      .limit(1)
      .single();

    if (error) {
      setErrorMessage("Error fetching story: " + error.message);
    } else {
      setStory(data);
    }
  }

  if (!story) return;

  const renderStepContent = () => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="max-w-3xl">
            <AudienceBuilder setAudience={setAudience} title={stepTitles[stepIndex]} />
          </div>
        );
      case 1:
        return (
          <div className="max-w-3xl">
            <GenerateLearningIdeas
              learningConcepts={learningConcepts}
              status={learningConceptStatus}
              generateLearningConcepts={generateLearningConcept}
              setLearningConcepts={setLearningConcept}
              selectedLearningConcept={selectedLearningConcept}
              setSelectedLearningConcept={setSelectedLearningConcept}
              title={stepTitles[stepIndex]}
            />
          </div>
        );
      case 2:
        return (
          <div className="max-w-3xl">
            <GenerateLearningSyllabus
              learningConcept={selectedLearningConcept || ""}
              status={syllabusStatus}
              generateSyllabus={generateSyllabus}
              setSyllabus={setSyllabus}
              syllabus={syllabus}
              title={stepTitles[stepIndex]}
            />
          </div>
        );
      case 3:
        return (
          <div className="max-w-3xl">
            <GenerateStoryIdeas
              ideas={ideas}
              status={ideasStatus}
              generateIdeas={generateIdeas}
              setSelectedIdea={setSelectedIdea}
              selectedIdea={selectedIdea}
              setIdeas={setIdeas}
              audience={audience}
              learningConcept={syllabus?.subject}
              title={stepTitles[stepIndex]}
            />
          </div>
        );
      case 4:
        return (
          <div className="max-w-3xl">
            <GenerateLogLine
              generateLogLine={generateLogLine}
              logLine={logLine}
              status={logLineStatus}
              setLogLine={setLogLine}
              selectedIdea={selectedIdea}
              title={stepTitles[stepIndex]}
            />
          </div>
        );
      case 5:
        return (
          <div className="max-w-3xl">
            <GenerateOnePager
              onePager={onePager}
              status={onePagerStatus}
              generateOnePager={generateOnePager}
              setOnePager={setOnePager}
              logline={logLine}
              audience={audience}
              title={stepTitles[stepIndex]}
            />
          </div>
        );
      case 6:
        return (
          <GenerateStyle
            onePager={onePager}
            status={onePagerStatus}
            generateOnePager={generateOnePager}
            setOnePager={setOnePager}
            logline={logLine}
            audience={audience}
            title={stepTitles[stepIndex]}
            story={story}
            setStory={setStory}
            onSave={() => {
              setStepIndex(stepIndex + 1);
            }}
          />
        );
      case 7:
        return (
          <GenerateCharacters
            charactersSummary={charactersSummary}
            status={charactersSummaryStatus}
            setCharactersSummary={setCharactersSummary}
            onePager={onePager}
            generateCharacters={generateCharactersSummary}
            audience={audience}
            title={stepTitles[stepIndex]}
          />
        );
      case 8:
        return (
          <SaveAll
            logLine={logLine}
            onePager={onePager}
            charactersSummary={charactersSummary}
            story={story}
            handleClose={() => navigate(`/admin`)}
            audience={audience}
            syllabus={syllabus}
          />
        );
      default:
        return null;
    }
  };

  return (
    <GlobalSideNavLayout activePageId={PAGE_STORY_WIZARD} storyId={storyId}>
      <div className="top-section sticky top-0 backdrop-blur-xl z-50 p-6">
        {errorMessage && (
          <div
            className="rounded bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 mb-4 cursor-pointer"
            onClick={() => setErrorMessage(null)}
          >
            {errorMessage}
          </div>
        )}
        <div className=" items-center mb-4">
          <h1 className="text-5xl font-bold">Story initialization</h1>
          <div className="w-full bg-gray-200 h-2 rounded-full mt-6">
            <div
              className="bg-zinc-900 h-2 rounded-full transition-all duration-300"
              style={{ width: `${((stepIndex + 1) / (maxStepIndex + 1)) * 100}%` }}
            />
          </div>
        </div>
      </div>

      <Card isFullWidth={true} className="">
        <CardContent>
          {renderStepContent()}

          <div className="flex justify-between items-center mt-6">
            <div className="flex gap-4">
              {stepIndex > 0 && (
                <SpokableButton
                  color="light"
                  onClick={() => setStepIndex(Math.max(0, stepIndex - 1))}
                >
                  Previous
                </SpokableButton>
              )}
              {stepIndex < maxStepIndex && (
                <SpokableButton onClick={() => setStepIndex(stepIndex + 1)}>Next</SpokableButton>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </GlobalSideNavLayout>
  );
};

export default StoryWizardPage;
