import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { AlertCircle, CheckCircle2 } from "lucide-react";
import type {
  ConceptItem,
  LearningPathElement,
  SyllabusOutput,
} from "@/types/learning_concept_prompts_generated_types";
import { useGroupedItems } from "@/components/admin/syllabusManagement/useSyllabusEditor";
import { Tables } from "@/types/database.ts";

// Types for the extracted components
type MomentMap = Map<string, Tables<"blueprint_moments">>;
type ItemsMap = Record<string, ConceptItem[]>;

// Utility function to check if prerequisites are fulfilled
const arePrerequisitesFulfilled = (
  concept: LearningPathElement,
  itemsByConceptId: ItemsMap,
  momentsBySubconceptId: MomentMap
): boolean => {
  if (!concept.prerequisites || concept.prerequisites.length === 0) return true;

  return concept.prerequisites.every((prereqConceptId) => {
    const prereqSubconcepts = itemsByConceptId[prereqConceptId] || [];
    // Check if ALL subconcepts of the prerequisite concept have moments
    return (
      prereqSubconcepts.length > 0 &&
      prereqSubconcepts.every((subconcept) => {
        return momentsBySubconceptId.has(subconcept.sub_concept_id);
      })
    );
  });
};

// Utility function to get missing prerequisites
const getMissingPrerequisites = (
  concept: LearningPathElement,
  itemsByConceptId: ItemsMap,
  momentsBySubconceptId: MomentMap,
  syllabus: SyllabusOutput
): string[] => {
  if (!concept.prerequisites || concept.prerequisites.length === 0) return [];

  return concept.prerequisites
    .filter((prereqId) => {
      const prereqSubconcepts = itemsByConceptId[prereqId] || [];
      // A prerequisite is missing if ANY of its subconcepts don't have moments
      return (
        prereqSubconcepts.length === 0 ||
        prereqSubconcepts.some(
          (subconcept) => !momentsBySubconceptId.has(subconcept.sub_concept_id)
        )
      );
    })
    .map(
      (prereqId) => syllabus?.learning_path.find((c) => c.concept_id === prereqId)?.concept_name
    )
    .filter(Boolean) as string[];
};

// Custom hook for moments-related functionality
const useMomentsBySubconceptId = (allMoments: Tables<"blueprint_moments">[] | null): MomentMap => {
  // Simplified by removing useMemo - compute the map directly
  if (!allMoments) return new Map<string, Tables<"blueprint_moments">>();

  const map = new Map<string, Tables<"blueprint_moments">>();
  allMoments.forEach((moment) => {
    if (moment.blueprint_learning_sub_concept_id) {
      map.set(moment.blueprint_learning_sub_concept_id, moment);
    }
  });
  return map;
};

// PrerequisiteBadge Component
interface PrerequisiteBadgeProps {
  concept: LearningPathElement;
  prerequisitesMet: boolean;
  missingPrereqs: string[];
}

const PrerequisiteBadge: React.FC<PrerequisiteBadgeProps> = ({
                                                               concept,
                                                               prerequisitesMet,
                                                               missingPrereqs
                                                             }) => {
  return (
    <HoverCard>
      <HoverCardTrigger>
        <Badge
          variant={prerequisitesMet ? "secondary" : "destructive"}
          className="ml-2"
        >
          {prerequisitesMet
            ? `${concept.prerequisites?.length} prerequisites met`
            : `${missingPrereqs.length} prerequisites needed`}
        </Badge>
      </HoverCardTrigger>
      <HoverCardContent className="w-96 p-4 bg-white rounded-xl shadow-lg">
        <div className="space-y-3">
          <div className="flex items-center gap-2 pb-2 border-b">
            <h4 className="text-lg font-semibold">Prerequisites</h4>
          </div>
          {prerequisitesMet ? (
            <div className="flex items-center gap-2 p-2 bg-green-50 rounded-lg">
              <p className="text-sm text-green-700 font-medium">
                ✓ All prerequisites are met
              </p>
            </div>
          ) : (
            <div className="space-y-2">
              <p className="text-sm font-medium text-red-600">
                Missing prerequisites:
              </p>
              <ul className="space-y-1">
                {missingPrereqs.map((prereq, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-2 text-sm text-gray-700 pl-2"
                  >
                    • {prereq}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

// MomentDetailsHoverCard Component
interface MomentDetailsHoverCardProps {
  subConceptId: string;
  momentsBySubconceptId: MomentMap;
}

const MomentDetailsHoverCard: React.FC<MomentDetailsHoverCardProps> = ({
                                                                         subConceptId,
                                                                         momentsBySubconceptId
                                                                       }) => {
  return (
    <HoverCard>
      <HoverCardTrigger>
        <Badge variant="secondary" className="flex items-center gap-1">
          <CheckCircle2 className="h-3 w-3" />
          Covered
        </Badge>
      </HoverCardTrigger>
      <HoverCardContent className="w-96 p-4 bg-white rounded-xl shadow-lg">
        <div className="space-y-3">
          <div className="flex items-center gap-2 pb-2 border-b">
            <h4 className="text-lg font-semibold">Moment Details</h4>
          </div>
          {(() => {
            const moment = momentsBySubconceptId.get(subConceptId);
            return moment ? (
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Badge variant="outline">{moment.moment_type}</Badge>
                  <span className="text-sm font-medium">
                    {moment.moment_name || "Unnamed moment"}
                  </span>
                </div>
                {moment.moment_setting && (
                  <p className="text-sm text-gray-600">
                    Setting: {moment.moment_setting}
                  </p>
                )}
              </div>
            ) : null;
          })()}
        </div>
      </HoverCardContent>
    </HoverCard>
  );
};

// SubconceptCard Component
interface SubconceptCardProps {
  item: ConceptItem;
  concept: LearningPathElement;
  isSelected: boolean;
  isCovered: boolean;
  prerequisitesMet: boolean;
  showOnlyCovered: boolean;
  momentsBySubconceptId: MomentMap;
  onSelect: (item: ConceptItem, concept: LearningPathElement) => void;
}

const SubconceptCard: React.FC<SubconceptCardProps> = ({
                                                         item,
                                                         concept,
                                                         isSelected,
                                                         isCovered,
                                                         prerequisitesMet,
                                                         showOnlyCovered,
                                                         momentsBySubconceptId,
                                                         onSelect
                                                       }) => {
  const getCardClassName = () => {
    const baseClasses = "border transition-all duration-200";
    const stateClasses = showOnlyCovered
      ? "hover:border-black cursor-pointer"
      : (!prerequisitesMet || isCovered)
        ? "bg-gray-50 cursor-not-allowed"
        : "hover:border-black cursor-pointer";
    const selectedClasses = isSelected ? "bg-black text-white" : "";

    return `${baseClasses} ${stateClasses} ${selectedClasses}`;
  };

  return (
    <Card
      key={`${item.concept_id}-${item.sub_concept_id}`}
      className={getCardClassName()}
      onClick={() => onSelect(item, concept)}
    >
      <CardContent className="p-4 flex justify-between items-center">
        <div className="flex items-center gap-2">
          <h5 className="font-medium">{item.name}</h5>
          {!prerequisitesMet && !showOnlyCovered && <AlertCircle className="h-4 w-4 text-red-500" />}
          {isCovered && (
            <MomentDetailsHoverCard
              subConceptId={item.sub_concept_id}
              momentsBySubconceptId={momentsBySubconceptId}
            />
          )}
        </div>
        <div className="flex items-center gap-2">
          <Badge>{item.noun}</Badge>
          <Badge className="bg-white" variant="outline">
            {item.verb}
          </Badge>
          <Badge variant="secondary">{item.adjective}</Badge>
        </div>
      </CardContent>
    </Card>
  );
};

// ConceptAccordionItem Component
interface ConceptAccordionItemProps {
  concept: LearningPathElement;
  subconcepts: ConceptItem[];
  selectedSyllabusConcept: ConceptItem | null | undefined;
  momentsBySubconceptId: MomentMap;
  itemsByConceptId: ItemsMap;
  showOnlyCovered: boolean;
  syllabus: SyllabusOutput;
  onSelectSubconcept: (item: ConceptItem, concept: LearningPathElement) => void;
}

const ConceptAccordionItem: React.FC<ConceptAccordionItemProps> = ({
                                                                     concept,
                                                                     subconcepts,
                                                                     selectedSyllabusConcept,
                                                                     momentsBySubconceptId,
                                                                     itemsByConceptId,
                                                                     showOnlyCovered,
                                                                     syllabus,
                                                                     onSelectSubconcept
                                                                   }) => {
  const prerequisitesMet = arePrerequisitesFulfilled(concept, itemsByConceptId, momentsBySubconceptId);
  const missingPrereqs = getMissingPrerequisites(concept, itemsByConceptId, momentsBySubconceptId, syllabus);
  const hasPrerequisites = concept.prerequisites && concept.prerequisites.length > 0;

  return (
    <AccordionItem key={concept.concept_id} value={concept.concept_id}>
      <AccordionTrigger className="rounded-lg px-4">
        <div className="flex items-center gap-2">
          <span>{concept.concept_name}</span>
          {hasPrerequisites && !showOnlyCovered && (
            <PrerequisiteBadge
              concept={concept}
              prerequisitesMet={prerequisitesMet}
              missingPrereqs={missingPrereqs}
            />
          )}
        </div>
      </AccordionTrigger>
      <AccordionContent className="px-4">
        <div className="grid gap-2">
          {subconcepts.map((item: ConceptItem) => {
            const isSelected =
              selectedSyllabusConcept?.concept_id === item.concept_id &&
              selectedSyllabusConcept?.sub_concept_id === item.sub_concept_id;
            const isCovered = momentsBySubconceptId.has(item.sub_concept_id);

            return (
              <SubconceptCard
                key={`${item.concept_id}-${item.sub_concept_id}`}
                item={item}
                concept={concept}
                isSelected={isSelected}
                isCovered={isCovered}
                prerequisitesMet={prerequisitesMet}
                showOnlyCovered={showOnlyCovered}
                momentsBySubconceptId={momentsBySubconceptId}
                onSelect={onSelectSubconcept}
              />
            );
          })}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

// Main component
interface SyllabusSubConceptSelectorProps {
  syllabus: SyllabusOutput | null;
  setSyllabus: React.Dispatch<React.SetStateAction<SyllabusOutput | null>>;
  selectedSyllabusConcept: ConceptItem | null | undefined;
  setSelectedSyllabusConcept: React.Dispatch<React.SetStateAction<ConceptItem | null | undefined>>;
  allMoments: Tables<"blueprint_moments">[] | null;
  showOnlyCovered?: boolean;
}

const SyllabusSubConceptSelector: React.FC<SyllabusSubConceptSelectorProps> = ({
                                                                                 syllabus,
                                                                                 setSyllabus,
                                                                                 selectedSyllabusConcept,
                                                                                 setSelectedSyllabusConcept,
                                                                                 allMoments,
                                                                                 showOnlyCovered = false
                                                                               }) => {
  // Use custom hooks to simplify the component
  const itemsByConceptId = useGroupedItems(syllabus?.items ?? []);
  const momentsBySubconceptId = useMomentsBySubconceptId(allMoments);
  
  // Track which concept's accordion is open - set it to the selected subconcept's parent concept by default
  const [openAccordion, setOpenAccordion] = React.useState<string | undefined>(
    selectedSyllabusConcept?.concept_id
  );

  // Update the open accordion whenever selectedSyllabusConcept changes
  React.useEffect(() => {
    if (selectedSyllabusConcept) {
      setOpenAccordion(selectedSyllabusConcept.concept_id);
    }
  }, [selectedSyllabusConcept]);

  const handleSelectSubconcept = (item: ConceptItem, concept: LearningPathElement): void => {
    // In showOnlyCovered mode, we allow selection of covered subconcepts
    // Otherwise, don't allow selection if prerequisites are not met or if the subconcept is already covered
    if (
      !showOnlyCovered &&
      (!arePrerequisitesFulfilled(concept, itemsByConceptId, momentsBySubconceptId) ||
        momentsBySubconceptId.has(item.sub_concept_id))
    ) {
      return;
    }

    setSelectedSyllabusConcept(item);
    setOpenAccordion(concept.concept_id); // Open the accordion for the selected concept
    setSyllabus((prev) => {
      if (!prev) return null;
      return {
        ...prev,
        selectedSubconceptId: `${item.concept_id}-${item.sub_concept_id}`
      } as SyllabusOutput & { selectedSubconceptId?: string };
    });
  };

  // Early return if no syllabus is provided
  if (!syllabus) {
    return null;
  }

  // Filter concepts to only include those with at least one covered subconcept when showOnlyCovered is true
  // Simplified by removing useMemo - compute this value directly
  let filteredLearningPath = syllabus.learning_path;

  if (showOnlyCovered) {
    filteredLearningPath = syllabus.learning_path.filter((concept) => {
      const subconcepts = itemsByConceptId[concept.concept_id] || [];
      return subconcepts.some((item) => momentsBySubconceptId.has(item.sub_concept_id));
    });
  }

  return (
    <div className="max-w-4xl mx-auto space-y-4">
      <label className="block text-sm font-medium mb-2">{syllabus.subject}</label>
      {filteredLearningPath.length === 0 && showOnlyCovered ? (
        <div className="p-4 text-center text-gray-500">No covered subconcepts found.</div>
      ) : (
        <Accordion 
          type="single" 
          collapsible 
          className="space-y-2"
          value={openAccordion}
          onValueChange={setOpenAccordion}
        >
          {filteredLearningPath.map((concept: LearningPathElement) => {
            const allSubconcepts = itemsByConceptId[concept.concept_id] || [];

            // Filter subconcepts if showOnlyCovered is true
            const subconcepts = showOnlyCovered
              ? allSubconcepts.filter(item => momentsBySubconceptId.has(item.sub_concept_id))
              : allSubconcepts;

            if (subconcepts.length === 0) return null;

            return (
              <ConceptAccordionItem
                key={concept.concept_id}
                concept={concept}
                subconcepts={subconcepts}
                selectedSyllabusConcept={selectedSyllabusConcept}
                momentsBySubconceptId={momentsBySubconceptId}
                itemsByConceptId={itemsByConceptId}
                showOnlyCovered={showOnlyCovered}
                syllabus={syllabus}
                onSelectSubconcept={handleSelectSubconcept}
              />
            );
          })}
        </Accordion>
      )}
    </div>
  );
};

export default SyllabusSubConceptSelector;
