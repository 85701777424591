import React, { useEffect, useState } from "react";
import { SparklesIcon } from "@heroicons/react/16/solid";
import AutoResizeTextArea from "../AutoResizeTextArea.tsx";
import { useGenerateSteps } from "./useGenerateSteps.ts";
import { SpokableButton } from "../SpokableButton.tsx";
import { base64ToMp3Blob, uploadToSupabaseData } from "@/utils/mediaUtil.ts";
import { sanitizeForURL } from "@/utils/stringUtil.ts";
import StorageFileAudioPlayer from "../StorageFileAudioPlayer.tsx";
import { SOUND_BUCKET_NAME } from "@/constants/constant.ts";

interface GenerateSoundProps {
  soundPrompt: string | null;
  storyId: string;
  onSoundSelected: (soundUrl: string) => void;
}

const GenerateSound: React.FC<GenerateSoundProps> = ({ soundPrompt, storyId, onSoundSelected }) => {
  const [prompt, setPrompt] = useState(soundPrompt || "");
  const { soundOutput, generateSound, soundStatus } = useGenerateSteps();
  const [uploadedSoundUrl, setUploadedSoundUrl] = useState<string | null>(null);

  useEffect(() => {
    convertAndUploadSound();
  }, [soundOutput]);

  const convertAndUploadSound = async () => {
    if (!soundOutput) return;
    const soundUrl = await uploadToSupabaseData(
      storyId,
      base64ToMp3Blob(soundOutput.base64Audio),
      sanitizeForURL(prompt.substring(0, 30)) + ".mp3",
    );
    console.log("soundUrl", soundUrl);
    const url = soundUrl?.split("/").pop();
    if (url) setUploadedSoundUrl(url);
  };

  const handleGenerate = async () => {
    await generateSound({
      prompt: prompt,
    });
    setUploadedSoundUrl(null);
  };

  return (
    <div className="flex-grow overflow-y-auto p-6 pt-0">
      <div className="flex items-center space-x-4">
        <div className="flex-grow">
          <AutoResizeTextArea
            value={prompt}
            onChange={(e) => {
              setPrompt(e);
            }}
          />
        </div>
      </div>
      <SpokableButton onClick={handleGenerate} className="mb-4 mt-4">
        <SparklesIcon className="mr-2" />
        Generate
      </SpokableButton>
      {soundStatus && <div>{soundStatus}</div>}
      {soundOutput && uploadedSoundUrl && (
        <div>
          <StorageFileAudioPlayer
            storyId={storyId}
            fileName={uploadedSoundUrl}
            bucketName={SOUND_BUCKET_NAME}
          />
          <SpokableButton onClick={() => onSoundSelected(uploadedSoundUrl)} className="mb-4 mt-4">
            <SparklesIcon className="mr-2" />
            Save
          </SpokableButton>
        </div>
      )}
    </div>
  );
};

export default GenerateSound;
