// src/components/admin/storyGraph/sceneLevel/nodes/TeachingNode.tsx
import React from "react";
import { NodeProps } from "@xyflow/react";

import { BookOpen } from "lucide-react";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";
import { BaseMomentNode } from "@/components/admin/storyGraph/common/BaseMomentNode.tsx";

const LearningNode: React.FC<NodeProps<MomentNode>> = (props) => {
  return (
    <BaseMomentNode
      nodeProps={props}
      icon={<BookOpen className="w-5 h-5" />}
    />
  );
};

export default LearningNode;
