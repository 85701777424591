import { useEffect, useState } from "react";
import { PlusCircle } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  SettingForm,
  SettingFormValues,
} from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelSettingsForm";
import { ModelCard } from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelCard";
import {
  ModelForm,
  ModelFormValues,
} from "@/components/admin/generateImagesModal/ImageModelCRUD/ImageModelForm";
import {
  BlueprintImageModels,
  BlueprintImageModelSettings,
} from "@/components/admin/generateImagesModal/hooks/useImageGenModelsDatabase.tsx";
import { useImageModelDatabase } from "@/components/admin/generateImagesModal/ImageModelCRUD/useImageModelDatabase.ts";
import { SpokableButton } from "@/components/admin/SpokableButton.tsx";
import { Toaster } from "@/components/ui/toaster.tsx";
import { useSetRecoilState } from "recoil";
import { nestedPopupCountState } from "@/states/ModalState.ts";

export function ImageModelCRUD() {
  const [isModelDialogOpen, setIsModelDialogOpen] = useState(false);
  const [isSettingDialogOpen, setIsSettingDialogOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState<BlueprintImageModels | null>(null);
  const [selectedSetting, setSelectedSetting] = useState<BlueprintImageModelSettings | null>(null);
  const [selectedModelIdForSetting, setSelectedModelIdForSetting] = useState<string | null>(null);
  const setNestedPopupCount = useSetRecoilState(nestedPopupCountState);

  const {
    models,
    settings,
    isLoading,
    createOrUpdateModel,
    createOrUpdateSetting,
    deleteModel,
    deleteSetting,
  } = useImageModelDatabase();

  // Increment nested popup count when dialogs open
  useEffect(() => {
    if (isModelDialogOpen || isSettingDialogOpen) {
      setNestedPopupCount((prevCount: number) => prevCount + 1);
    }
    return () => {
      if (!isModelDialogOpen && !isSettingDialogOpen) {
        setNestedPopupCount((prevCount: number) => Math.max(0, prevCount - 1));
      }
    };
  }, [isModelDialogOpen, isSettingDialogOpen, setNestedPopupCount]);

  const handleModelSubmit = async (formData: ModelFormValues) => {
    const success = await createOrUpdateModel(formData, selectedModel);
    if (success) {
      closeModelDialog();
    }
  };

  const handleSettingSubmit = async (formData: SettingFormValues) => {
    const success = await createOrUpdateSetting(
      formData,
      selectedSetting,
      selectedModelIdForSetting,
    );
    if (success) {
      closeSettingDialog();
    }
  };

  const handleEditModel = (model: BlueprintImageModels) => {
    setSelectedModel(model);
    setIsModelDialogOpen(true);
  };

  const handleAddSetting = (modelId: string) => {
    setSelectedSetting(null);
    setSelectedModelIdForSetting(modelId);
    setIsSettingDialogOpen(true);
  };

  const handleEditSetting = (setting: BlueprintImageModelSettings) => {
    setSelectedSetting(setting);
    setSelectedModelIdForSetting(setting.image_model_id);
    setIsSettingDialogOpen(true);
  };

  const closeModelDialog = () => {
    setIsModelDialogOpen(false);
    setSelectedModel(null);
  };

  const closeSettingDialog = () => {
    setIsSettingDialogOpen(false);
    setSelectedSetting(null);
    setSelectedModelIdForSetting(null);
  };

  return (
    <div className="flex flex-col">
      <div className="p-6">
        <SpokableButton onClick={() => setIsModelDialogOpen(true)}>
          <PlusCircle className="mr-2 h-4 w-4" />
          Add Model
        </SpokableButton>
      </div>

      {/* Models List - using a more isolated approach for nested dialogs */}
      <div className="px-6 mb-6">
        <div className="flex-1 px-6 overflow-hidden min-h-0">
          {isLoading ? (
            <div className="text-center p-12">
              <p className="text-muted-foreground">Loading...</p>
            </div>
          ) : (
            <div className="h-full overflow-y-auto pr-2" style={{ maxHeight: "calc(70vh - 200px)" }}>
              <div className="space-y-6 pb-6">
                {models.map((model) => (
                  <ModelCard
                    key={model.id}
                    model={model}
                    settings={settings.filter((s) => s.image_model_id === model.id)}
                    onEdit={handleEditModel}
                    onDelete={deleteModel}
                    onAddSetting={handleAddSetting}
                    onEditSetting={handleEditSetting}
                    onDeleteSetting={deleteSetting}
                  />
                ))}
                {!isLoading && models.length === 0 && (
                  <div className="text-center p-12 border rounded-lg border-dashed">
                    <p className="text-muted-foreground">
                      No models yet. Click the button above to add your first model.
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog open={isModelDialogOpen} onOpenChange={setIsModelDialogOpen}>
        <DialogContent className="max-w-2xl" style={{ zIndex: 999 }}>
          <DialogHeader>
            <DialogTitle>{selectedModel ? "Edit Model" : "Add New Model"}</DialogTitle>
            <DialogDescription>
              Configure the blueprint model settings and parameters.
            </DialogDescription>
          </DialogHeader>
          <ModelForm
            onSubmit={handleModelSubmit}
            initialData={selectedModel || undefined}
            onCancel={closeModelDialog}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={isSettingDialogOpen} onOpenChange={setIsSettingDialogOpen}>
        <DialogContent className="max-w-2xl" style={{ zIndex: 999 }}>
          <DialogHeader>
            <DialogTitle>{selectedSetting ? "Edit Setting" : "Add New Setting"}</DialogTitle>
            <DialogDescription>Configure a new setting for this model.</DialogDescription>
          </DialogHeader>
          <SettingForm
            onSubmit={handleSettingSubmit}
            initialData={selectedSetting || undefined}
            onCancel={closeSettingDialog}
          />
        </DialogContent>
      </Dialog>

      <Toaster />
    </div>
  );
}
