import React from "react";
import { NodeProps } from "@xyflow/react";

import { BookCheck } from "lucide-react";
import { MomentNode } from "@/components/admin/storyGraph/common/types.ts";
import { BaseMomentNode } from "@/components/admin/storyGraph/common/BaseMomentNode.tsx";

const TestingNode: React.FC<NodeProps<MomentNode>> = (props) => {
  return (
    <BaseMomentNode
      nodeProps={props}
      borderColor="rgb(147 197 253)"
      icon={<BookCheck className="w-5 h-5 text-blue-500" />}
    />
  );
};

export default TestingNode;
